import React from 'react';
import { Card, Image, Tag } from 'antd';

import Author from '../common/Author';
import { useSettings } from '../hooks/useSettings';
import { useTags } from '../hooks/useTags';

import { COLOR } from 'constants.js';
import { VideoCameraFilled } from '@ant-design/icons';

const PosterCard = ({ poster, showAuthors = true, gotoPoster }) => {
	const [settingsLoading, settings] = useSettings();
	const [tagsLoading, tagsMap] = useTags();
	const loading = settingsLoading || tagsLoading;

	if (loading) return null;

	const track = settings.trackMap.get(poster.trackId);

	return (
		<Card onClick={gotoPoster} className="gx-mb-0 poster-card">
			<Image
				src={poster.image || require('assets/images/placeholder.png')}
				alt="thumbnail"
				className="poster-thumbnail poster_img_thumb"
			></Image>
			<div className="gx-pr-4 gx-pl-4 gx-pt-4 gx-pb-3">
				<h5 style={{ color: '#b9b9b9' }} className='gx-mb-3'>
					{track?.name ?? poster.trackId}
				</h5>
				<h3 className="gx-mb-2" style={{ fontWeight: 300 }}>
					{poster.title}
				</h3>
				{poster.tagIds.map(tagId => (
					<Tag
						key={tagId}
						className="gx-mt-3 staticTagColor"
						// color={settings.primary ?? COLOR.PRIMARY}
					>
						{tagsMap.get(tagId)?.name ?? tagId}
					</Tag>
				))}
			</div>
			<div className="gx-p-4 gx-mt-auto">
				{showAuthors &&
					poster.authors
						.filter(author => author.original.presenter)
						.map(author => (
							<div key={author.uuid}>
								<Author author={author} />
							</div>
						))}
			</div>
		</Card>
	);
};

export default PosterCard;
