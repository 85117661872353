import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import PosterCardGrid from './PosterCardGrid';
import PosterCardList from './PosterCardList';
import PosterCardQuick from './PosterCardQuick';

import { ViewContext } from './ViewContext';
import { VIEW } from '../../constants';

const PostersView = ({ posters, showAuthors }) => {
	const history = useHistory();
	const { view } = useContext(ViewContext);

	const gotoPoster = poster => {
		history.push(`./poster/${poster.id}`);
	};

	return (
		<main>
			<div className="poster-grid">
					{posters.map((poster, index) => (
						<PosterCardGrid
							poster={poster}
							key={index}
							showAuthors={showAuthors}
							gotoPoster={() => gotoPoster(poster)}
						/>
					))}
				</div>
			{/* {view === VIEW.GRID && (
				<div className="poster-grid">
					{posters.map((poster, index) => (
						<PosterCardGrid
							poster={poster}
							key={index}
							showAuthors={showAuthors}
							gotoPoster={() => gotoPoster(poster)}
						/>
					))}
				</div>
			)}
			{view === VIEW.LIST && (
				<div>
					{posters.map((poster, index) => (
						<PosterCardList
							poster={poster}
							key={index}
							showAuthors={showAuthors}
							gotoPoster={() => gotoPoster(poster)}
						/>
					))}
				</div>
			)}
			{view === VIEW.QUICK &&
				posters.map((poster, index) => (
					<div key={index} className="gx-mb-5">
						<PosterCardQuick poster={poster} gotoPoster={() => gotoPoster(poster)} />
					</div>
				))} */}
		</main>
	);
};

export default PostersView;
