import {RECIEVE_ALL_POSTS, REQUEST_ALL_POSTS, PUSH_POST_SOCIAL, RECIEVE_PUSH_POST
} from "../../constants/ActionTypes";

const INIT_STATE = {
  postList: {},
  loading:false
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REQUEST_ALL_POSTS:{
      return {
        ...state,
        loading : true
      }
    }
    
    case RECIEVE_ALL_POSTS: {
      if( !Object.values(action.payload)[0].post){
        Object.values(action.payload)[0].post=state.postList[Object.keys(action.payload)[0]].post;
      }
      return {
        ...state,
        postList: Object.assign({},state.postList,action.payload),
        loading:false
      }
    }
    case PUSH_POST_SOCIAL: {
      return {
        ...state,
        loading : true
      }
    }
    case RECIEVE_PUSH_POST:{
        let newPostList = state.postList;
        if(action.payload.deletePost){
          //handle post delete
          if(action.payload.comment){
            delete newPostList[action.payload.key].comments[action.payload.post.CommentID];
            delete newPostList[action.payload.key].allData[action.payload.post.CommentID];
          }
          else{
            delete newPostList[action.payload.key].post[action.payload.post.CommentID];
            delete newPostList[action.payload.key].allData[action.payload.post.CommentID];
          }
        }
        else{
          //handles new/edit posts
          let obj = {};
          obj[action.payload.post.CommentID]=action.payload.post;
          if(action.payload.newPost){
            newPostList[action.payload.key].post=Object.assign({},obj, newPostList[action.payload.key].post);
            newPostList[action.payload.key].allData=Object.assign({},obj, newPostList[action.payload.key].allData);
          }
          else{
            newPostList[action.payload.key].comments=Object.assign({},obj, newPostList[action.payload.key].comments);
            newPostList[action.payload.key].allData=Object.assign({},obj, newPostList[action.payload.key].allData);
          }
        }
        
      return {
        ...state,
        postList: {...newPostList},
        loading:false
      }

    }

    default:
      return state;
  }
}
