import {
    SIGNIN_USER_SUCCESS,
  } from "constants/ActionTypes";

const INIT_STATE = {
    config:'',
    user: null,
  };



  export default (state = INIT_STATE, { type, payload }) => {
	switch (type) {
		case SIGNIN_USER_SUCCESS: {
			return {
				...state,
				config: payload.config,
				user: payload.user,
			};
		}
		default:
			return state;
	}
};
