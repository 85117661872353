export const TAB = {
	TITLE: 'title',
	PRESENTER: 'presenter',
	SESSION: 'session',
	ORGANIZATION: 'organization',
};

export const VIEW = {
	GRID: 'grid',
	LIST: 'list',
	QUICK: 'quick',
};

export const COLOR = {
	PRIMARY: '#0d7dc2',
	PRIMARY_DARK: '#105986',
	PRIMARY_LIGHT: '#1398eb',
};
