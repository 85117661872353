import React, { useContext } from 'react';

import { connectStateResults } from 'react-instantsearch-dom';
import { Card, Skeleton, Image, Empty } from 'antd';

import PostersView from '../PostersView';
import { ReactComponent as Organization } from 'assets/icons/organization.svg';
import { ViewContext } from '../ViewContext';
import { VIEW } from 'constants.js';

const StateResults = ({ searchResults, loading }) => {
	const { view } = useContext(ViewContext);
	const searchLoading = loading || !searchResults;

	if (searchLoading)
		return (
			<section className="poster-grid">
				{[...Array(8)].map((_, index) => (
					<Card key={index} className="card gx-mb-0 -shadow-1">
						<Image
							className="card-image"
							style={{ height: '7em' }}
							alt="placeholder"
							src={require('assets/images/placeholder.png')}
						/>
						<Skeleton active paragraph={{ rows: 5 }}></Skeleton>
					</Card>
				))}
			</section>
		);
	if (searchResults.nbHits === 0) return <Empty style={{ margin: '20em 0' }} />;

	return (
		<section className="">
			{view !== VIEW.QUICK && (
				<p>
					showing {searchResults.hits.length} out of {searchResults.nbHits} results
				</p>
			)}
			{searchResults.hits.map((organizationPoster, index) => (
				<Card
					title={
						<div className="gx-d-flex">
							<h3
								className="gx-text-white gx-font-weight-semi-bold gx-mb-0"
								style={{ whiteSpace: 'normal' }}
							>
								{organizationPoster.organization}
							</h3>
							<Organization
								className="gx-ml-auto gx-pl-3"
								style={{ flexShrink: 0, flexBasis: '2.75rem' }}
							/>
						</div>
					}
					className="author-card"
					key={index}
				>
					<PostersView posters={organizationPoster.posters} />
				</Card>
			))}
		</section>
	);
};

export default connectStateResults(StateResults);
