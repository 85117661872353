import React, { useEffect } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { Input } from 'antd';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import useDocumentWidth from '../../hooks/useDocumentWidth';
import { useSelector, useDispatch } from 'react-redux';
import { updateSearchParams } from '../../../store/actions/designModes';

const SearchBox = ({ currentRefinement, refine }) => {
	const { isMobile } = useDocumentWidth();
	const searchParams = useSelector(state => state?.modes?.params);
	const dispatch = useDispatch();

	useEffect(() => {
		if (searchParams?.toString() !== '') {
			refine(searchParams);
			dispatch(updateSearchParams(''));
		}
	}, [searchParams]);

	return (
		<Input.Search
			className={!isMobile ? 'searchBox searchBoxWidth' : 'searchBox'}
			defaultValue={currentRefinement ? currentRefinement : searchParams}
			onSearch={value => refine(value)}
			size="large"
			placeholder="Search..."
			allowClear
			onPressEnter={e=> {
				// e.preventDefault();
				refine(e.target.value);
			}}
			onChange={e => {
				if (e?.target?.value?.trim() == '') dispatch(updateSearchParams(''));
			}}
			// prefix={<Search className="gx-mr-2" style={{ width: '1em', fill: 'gray' }} />}
		/>
	);
};

export default connectSearchBox(SearchBox);
