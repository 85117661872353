import React from "react";
import { Spin } from "antd";

const Loading = () => {
	return (
		<main className="gx-d-flex" style={{ height: "100vh" }}>
			<Spin
				className="gx-m-auto"
				size="large"
				spinning
				tip={<h3 className="gx-mt-3 gx-text-primary gx-font-weight-semi-bold">Please wait...</h3>}
			/>
		</main>
	);
};

export default Loading;
