import React, { useEffect, useRef, useState } from 'react';

import { Route, Switch, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MainPage from 'components/MainPage';
import PosterPage from 'components/PosterPage';

import { useSettings } from 'components/hooks/useSettings';
import EmptyPage from 'components/EmptyPage';
import Loading from 'components/common/Loading';

import './styles/custom.css';
import { KIOSK_MODE, TAKEOVER_MODE } from './constants/ModesConfig';
import { Modal } from 'antd';

const App = ({ match }) => {
	const [settingsLoading, settings] = useSettings();
	const history = useHistory();
	const { value, timeout } = useSelector(state => state.modes?.activeMode);
	const timeoutRef = useRef();
	const [isShowModal, setIsShowModal] = useState(false);

	useEffect(() => {
		localStorage.setItem(
			'inactivityRedirect',
			JSON.stringify({ timeout: timeout, mode: value }),
		);
	}, [timeout, value]);

	useEffect(() => {
		localStorage.removeItem('inactivityRedirect');
		inactivityEventCapture();
	}, []);

	useEffect(() => {
		if (settingsLoading) return;
		document.title = settings.title;
	}, [settingsLoading]);

	const inactivityEventCapture = () => {
		['mousemove', 'mousedown', 'touchstart', 'click', 'keypress', 'load'].forEach(e => {
			document.addEventListener(
				e,
				() => {
					if (localStorage.getItem('inactivityRedirect') !== null) resetTimer();
				},
				true,
			);
		});
	};

	const resetTimer = () => {
		let inactivityObj;
		try {
			inactivityObj = JSON.parse(localStorage.getItem('inactivityRedirect'));
		} catch (error) {
			inactivityObj = undefined;
		}
		clearTimeout(timeoutRef?.current);
		if (inactivityObj && Number(inactivityObj.timeout) > 0) {
			timeoutRef.current = setTimeout(() => {
				if (
					window.location.pathname.includes('poster') &&
					(inactivityObj.mode === KIOSK_MODE || inactivityObj.mode === TAKEOVER_MODE)
				) {
					setIsShowModal(true);
				}
			}, inactivityObj.timeout * 1000);
		}
	};

	useEffect(() => {
		if (Boolean(settings)) {
			// settings can be null
			document.documentElement.style.setProperty('--primary', settings.primary);
			document.documentElement.style.setProperty('--primary-dark', settings.primaryDark);
			document.documentElement.style.setProperty('--primary-light', settings.primaryLight);
		}
	}, [settings]);

	useEffect(() => {
		let timer;
		timer = setTimeout(() => {
			if (isShowModal) {
				const fullScreenImg = document.querySelector('.iv-fullscreen')
				if (fullScreenImg) {
					fullScreenImg.style.display = 'none'
				}
				history.push('../main');
				setIsShowModal(false);
			}
		}, 5000);
		return () => {
			clearTimeout(timer);
		};
	}, [isShowModal]);

	if (settingsLoading) return <Loading />;

	return (
		<main
		// style={{
		// 	'--primary': settings.primary,
		// 	'--primary-dark': settings.primaryDark,
		// 	'--primary-light': settings.primaryLight,
		// }}
		>
			<Switch>
				<Route exact path={`${match.path}/main`} component={MainPage} />
				<Route exact path={`${match.path}/poster/:posterId`} component={PosterPage} />
				<Route component={EmptyPage} />
			</Switch>
			<Modal
				title="Confirmation"
				visible={isShowModal}
				cancelButtonProps={{ style: { display: 'none' } }}
				onOk={() => setIsShowModal(false)}
				okText="Yes"
				closable={false}
			>
				<p>Are you there?</p>
			</Modal>
		</main>
	);
};

export default App;
