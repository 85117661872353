import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { requestSettings } from 'store/actions/settings';

export const useSettings = () => {
	const dispatch = useDispatch();
	const { appDir, urlId } = useParams();
	const settings = useSelector(state => state.settings.settings);
	const fetched = useSelector(state => state.settings.fetched);

	const loading = !fetched;

	useEffect(() => {
		if (fetched) return;
		dispatch(requestSettings({ appDir, urlId }));
	}, []);

	return [loading, settings];
};
