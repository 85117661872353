import React from 'react';

import { useSettings } from '../hooks/useSettings';
import { Tag } from 'antd';

import { COLOR } from 'constants.js';

const Author = ({ author, nameClass }) => {
	const [, settings] = useSettings();

	const getAvatar = () => {
		if (author.avatar)
			return author.avatar.startsWith('http')
				? author.avatar
				: `${settings.appUrl}/pictures/${author.avatar}`;
		else return getFallbackAvatar();
	};

	const getFallbackAvatar = () =>
		`https://ui-avatars.com/api/?background=${
			settings.primaryDark?.substring(1) ?? '105986'
		}&color=fff&name=${author.firstName}+${author.lastName}`;

	return (
		<div className="gx-d-flex">
			<div>
				<div className="gx-d-flex gx-align-items-center gx-mb-1">
					<h3 className={`gx-mb-0 ${nameClass}`} style={{fontWeight: 300}}>
						{author.prefix} {author.firstName} {author.lastName}
					</h3>
				</div>
				<p className="gx-mb-0" style={{ whiteSpace: 'normal', fontSize: '.85rem' }}>
					{author.company}
				</p>
			</div>
		</div>
	);
};

export default Author;
