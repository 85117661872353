import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import { REQUEST_TAGS, receiveTags } from '../actions/tags';

const getTags = async ({ source }) =>
	await axios.get(
		`https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm?source=${source}&module=client&component=tags&function=see_tags`,
	);

function* getTagsSaga({ payload }) {
	const results = yield call(getTags, payload);
	yield put(receiveTags(results));
}

function* getTagsWatcher() {
	yield takeLatest(REQUEST_TAGS, getTagsSaga);
}

export default function* () {
	yield all([fork(getTagsWatcher)]);
}
