import {all, call, fork, put, takeEvery, takeLatest, select} from "redux-saga/effects";
import {recieveSocialData, recieveSetSocialData} from '../actions/Social';

import {REQUEST_ALL_POSTS, PUSH_POST_SOCIAL} from '../../constants/ActionTypes';

import {socialFeedData} from "../../components/wall/wall";

let base_url  = "https://lag2r3gpz4.execute-api.us-east-2.amazonaws.com/dev/";

const fetchPostData = async (config, params, refresh) => {
  let url_discussion='';
  let paramData={};
      url_discussion = `${base_url}/topic/${params.topic_identifier}`;
      let tmp_posts = await fetch(url_discussion).then(response => response.json());
      return(tmp_posts);

}
function compareValues(key, order = 'asc') {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = (typeof a[key] === 'string')
      ? a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === 'string')
      ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  };
}
const fetchTagArray = async (topPosts) => {
  let hm= {};
  topPosts.map((topic) => {
    let abstractID = topic.tags.filter(s => s.includes('abstract'));
    let sessionID = topic.tags.filter(s => s.includes('session'));
    let tagId = abstractID && abstractID.length>0?abstractID: sessionID?sessionID:null;
    if(tagId!=null && tagId.length>0){
      topic.RelatedTags=[{tag:tagId[0]}];
      (hm[tagId] && hm[tagId].length>0)?hm[tagId].push(topic.CommentID):hm[tagId]=[topic.CommentID];
    }
    
  })
  return {tagArray:hm, posts:topPosts};
}
const fetchTagInformation = async (tagArrayObj, config) => {
  let hm= {};
  if(Object.keys(tagArrayObj).length>0){
    let tagList =  Object.keys(tagArrayObj).toString();
    var myHeaders = new Headers();
    var formdata = new FormData();
    formdata.append("tag", tagList);
    formdata.append("source", config.source_hex);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };


    let result = await fetch("https://masterapp.econference.io/masterapp_summer2012/controllers/communities/api_tagid.cfm", requestOptions).then(response => response.json())
    if(result){
      let tmp_tags = result;
      if(tmp_tags && tmp_tags.length>0){
        tmp_tags.map((tag) =>{ 
        hm[tag.tag]= tag.label;
      })
    }}
    
  }
   return hm;  
}
const fetchContactInformation = async (allPosts, contacts, config, user) => {
  let missingIds = [];
  let cont = Object.values(contacts);
  let hm= {};
  for(let i=0; i<allPosts.length; i++){
    if(cont && allPosts[i].author.id){
      let info = cont.filter((v) => v?.uuid==allPosts[i].author.id)[0];
      if(!info){
        missingIds.push(allPosts[i].author.id)
      }
    }
  }
  if(user.uuid){
    let info = cont.filter((v) => v?.uuid==user?.uuid)[0];
      if(!info){
        missingIds.push(user.uuid)
      }
  }
  if(missingIds.length>0){
    let url = `${base_url}contact/${config.source_hex}`;
    let uuids = {"uuids":missingIds}
    let paramData={
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(uuids),
        redirect: 'follow'
      }
     let contactsData = await fetch(url,paramData).then(res => res.json())
      if(contactsData.length>0){
        contactsData.map(obj => {
          hm[obj.uuid] = obj;
        })
      }
     return hm;
  }
  else{
    return hm;
  }
  
}
const generateAllDataHM = async (postData) => {
  let hm= {};
  if(postData.length>0){
    postData.map(post => {
      hm[post.CommentID] = post
    })
  }
  return {allData:hm}
}
const generatePostListHM = async (topPosts) => {
  let hm= {};
  if(topPosts.length>0){
    topPosts.map(post => {
      hm[post.CommentID] = post
    })
  }
  return {post:hm}
}
const generateCommentListHM = async (commentPosts) => {
  let hm= {};
  if(commentPosts.length>0){
    commentPosts.map(post => {
      hm[post.CommentID] = post
    })
  }
  return {comments:hm}
}

function* generateObjectSet(postData, topPosts, commentPosts, tagArrayObj, tagInfoArray, contInfo, key) {

  let objectSet = {};
  objectSet[key] = {...objectSet, ...yield generateAllDataHM(postData), ...yield generatePostListHM(topPosts), ...yield generateCommentListHM(commentPosts),
  tagArray: tagArrayObj,
  tagInfoArray: tagInfoArray,
  contactInfo:contInfo};
 
  return objectSet;
}

function* getSocialData(action, state) {
  try {
    let config = action.payload.config;

    if(action.payload.refresh){
      //refresh logic
      const state = yield select();
      let contacts = state.session.program.contacts;
      let newPosts=[];
      let allData = state.social.postList[action.payload.params.key].allData;
      let postData =  yield fetchPostData(config, action.payload.params, action.payload.refresh);
      for(let i=0; i<postData.length; i++){
        if(!allData[postData[i].CommentID]){
          newPosts.push(postData[i]);
        }
      }
      let topPosts = newPosts.filter(v => v.ParentCommentID==0);
      let commentPosts = newPosts.filter(v => v.ParentCommentID!=0);
      let tagArray =  yield fetchTagArray(topPosts);
      let tagArrayObj =  tagArray.tagArray;
      topPosts=tagArray.posts;
      let tagInfoArray =  yield fetchTagInformation(tagArrayObj, config);
      let contInfo =  yield fetchContactInformation(newPosts, contacts, config, state.auth.user);
      let key = action.payload.params.key;
      let objectSet = {...yield generateObjectSet(postData, topPosts, commentPosts, tagArrayObj, tagInfoArray, contInfo, key)}
      let newSet = state.social.postList;
      newSet[action.payload.params.key].post=Object.assign({},objectSet[action.payload.params.key].post, newSet[action.payload.params.key].post);
      newSet[action.payload.params.key].comments=Object.assign({},objectSet[action.payload.params.key].comments, newSet[action.payload.params.key].comments);
      newSet[action.payload.params.key].tagArray=Object.assign({},objectSet[action.payload.params.key].tagArray, newSet[action.payload.params.key].tagArray);
      newSet[action.payload.params.key].tagInfoArray=Object.assign({},objectSet[action.payload.params.key].tagInfoArray, newSet[action.payload.params.key].tagInfoArray);
      newSet[action.payload.params.key].contactInfo=Object.assign({},objectSet[action.payload.params.key].contactInfo, newSet[action.payload.params.key].contactInfo);
      newSet[action.payload.params.key].allData=objectSet[action.payload.params.key].allData;
      yield put(recieveSocialData(newSet));
    }
    else{
      //initial fetch
      const state = yield select();
      let contacts ={};
      let data={};
      let postData =  yield fetchPostData(config, action.payload.params, action.payload.refresh);
      let topPosts = postData.filter(v => v.ParentCommentID==0);
      let commentPosts = postData.filter(v => v.ParentCommentID!=0);
      let tagArray =  yield fetchTagArray(topPosts);
      let tagArrayObj =  tagArray.tagArray;
      topPosts=tagArray.posts;
      let tagInfoArray =  yield fetchTagInformation(tagArrayObj, config);
      let contInfo =  yield fetchContactInformation(postData, contacts, config, state.auth.user);
      let key = action.payload.params.key;
      let objectSet = {...yield generateObjectSet(postData, topPosts, commentPosts, tagArrayObj, tagInfoArray, contInfo, key)}
      yield put(recieveSocialData(objectSet));
    }
     
  } catch (error) {
    console.log(error);
  }
}

function* setSocialData(action) {
  try {
    let data = action.payload;
    yield put(recieveSetSocialData(data));
  } catch (error) {
  }
}

export function* fetchPosts() {
  yield takeLatest(REQUEST_ALL_POSTS, getSocialData);
}

export function* setPosts() {
  yield takeLatest(PUSH_POST_SOCIAL, setSocialData);
}

export default function* rootSaga() {
  yield all([fork(fetchPosts),fork(setPosts)]);
}

