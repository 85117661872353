import {REQUEST_ALL_POSTS, RECIEVE_ALL_POSTS, PUSH_POST_SOCIAL, RECIEVE_PUSH_POST} from "constants/ActionTypes";


export const requestSocialData = (config,params,user,contacts, refresh, timestamp, allData, tagsInfo) => {
  return dispatch => dispatch({
        type: REQUEST_ALL_POSTS,
        payload: {config , params, user, contacts, refresh, timestamp, allData, tagsInfo}
      });
};
export const recieveSocialData = (data) => {
  return dispatch => dispatch({
        type: RECIEVE_ALL_POSTS,
        payload: data
      });
};
export const setSocialData = (postObj) => {
  return dispatch => dispatch({
        type: PUSH_POST_SOCIAL,
        payload: postObj
      });
};
export const recieveSetSocialData = (postObj) => {
  return dispatch => dispatch({
        type: RECIEVE_PUSH_POST,
        payload: postObj
      });
};
