import React, {useEffect, useState} from "react";
import PostItem from "./PostItem";
import WriteBox from "../../../components/wall/WriteBox/index";
import {AddPostBundler, fetchTagInformation} from "../wall";
import InfiniteScroll from 'react-infinite-scroll-component';

// @ts-ignore
import { connect } from "react-redux";

import { setSocialData } from '../../../store/actions/Social';
import { bindActionCreators, Dispatch, AnyAction } from "redux";

interface Props {
  user:{
    Biography: string,
    City: string,
    Company: string,
    ContactTitle: string,
    Country: string,
    CustID: number,
    DateAdded: string,
    Email: string,
    Firstname: string,
    Lastname: string,
    Phone: string,
    Picture: string,
    ReviewID: number,
    SourceHexID: string,
    State: string,
    Zip: string,
    uuid: string,
    Degrees:string,
    Prefix:string,
    CustomField_3:string,
    CustomField_4:string,
    limited_access_attendee?: boolean
  },
  allPostData:any,
  setSocialData:any,
  wall:boolean, 
  sortBy:string,
  config:any,
  params:{
    topic_identifier:string,
    pageType:string, 
    NewPostTags:string[], 
    appliedFilters:string[], 
    DisplayRelatedTags:string[], 
    ads:Boolean,
    key:string,
    qna?:boolean
  },
  getPostTypeNumbers?:(data: string | any[])=>void,
  SetTagTable?:(table:any)=>void,
  postList?:any,
}
interface dataList {
	CommentID: string;
    id: number,
    text: string,
    author: {firstname:string,lastname:string,company:string,image:string},
    created_timestamp: string,
    mediaList: [],
    RelatedTags:any,
    isLike: boolean,
    commentList: any,
    message_object:{
      message_type: string,
      body: string,
      title: string,
      attachments:[]
    },
    likes:[ {ReviewID: string,
      timestamp: number}]
    TotalComments:number,
    tags:any
}
const PostList = (props:Props) => {
  const [postList, setPostList] = useState<dataList[]>([]);
  const [postItems, setpostItems] = useState<dataList[]>([]);
  const [user, setUser] = useState(props.user);
  const [hasMore, setHasMore] = useState(true);
  const [topicLimit, setTopicLimit] = useState(5);
 
  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  useEffect(() => {
    if(props.wall && props.postList){
      setPostList(props.postList);
      setpostItems(props.postList.slice(0,topicLimit));
    }
      
  }, [props.postList, props.allPostData]);
  

  function addPost (commentText: any, imageList: any, parentPost: any, config: any, params:any){

    return new Promise(function(resolve, reject) {
      AddPostBundler(commentText, imageList, parentPost, config, params, user).then((res:any) =>{
        let data = props.allPostData;
        let tagTable = data[props.params.key].tagInfoArray;
        if(!parentPost){
          // if top level post
          //Add related tag info  
          let tempRelatedTags:any=[];
          if(res.RelatedTags.length>0){
          for(let j=0; j<res.RelatedTags.length; j++){
            //for session posts
            if(typeof(res.RelatedTags[j])=='string'){
                if(tagTable[res.RelatedTags[j]]){
                  //already have tag info 
                  tempRelatedTags.push({tag:res.RelatedTags[j],label:tagTable[res.RelatedTags[j]].title});
                  res.RelatedTags=tempRelatedTags;
                  let obj = {post:res, key:props.params.key, newPost:true}
                  props.setSocialData(obj);
                }
                else{
                  //first post, fetch tag info 
                  let tagInfo:any= {};
                    tagInfo[res.RelatedTags[j]] = {
                    tag:res.RelatedTags[j], label:''
                  }
                  fetchTagInformation(tagInfo,config).then((tagdata)=>{
                    tagInfo[tagdata[0].tag].label=tagdata[0].label;
                    tagTable= {...tagTable, ...tagInfo}
                    tempRelatedTags.push(tagInfo[tagdata[0].tag]);
                    res['RelatedTags']=tempRelatedTags;
                    let obj = {post:res, key:props.params.key, newPost:true};
                    props.setSocialData(obj);
                  })
                }
              }}
            }
            else{
              //no related tag i.e conference post
              let obj = {post:res, key:props.params.key, newPost:true}
              props.setSocialData(obj);
            }
          }
          else{
            //dispatch for comments
            let obj = {post:res, key:props.params.key, newPost:false}
            props.setSocialData(obj)
          }
          resolve(res);
      })
    })
  }


  const fetchMoreData = () =>{
    let newLimit = topicLimit + 5;
      setTopicLimit(topicLimit + 5);
    if(postList.length<topicLimit){
      setHasMore(false);
      setpostItems(postList);
    }
    setpostItems(postList.slice(0,newLimit));
  }
  
if(postList){
  return (
    <>
      <div style={{clear:'both'}}></div>
      {!user.limited_access_attendee &&
      <WriteBox addPost={addPost} user={user} config={props.config} params={props.params}/> }
      {postItems && postItems.length>0 &&<InfiniteScroll
                              dataLength={postItems.length}
                              next={fetchMoreData}
                              hasMore={hasMore}
                              loader={<h4>Loading...</h4>}
                              scrollThreshold={0.9}
                              scrollableTarget={'scrollablediv'}
                            >
         {postItems && postItems.length>0 && postItems.map((post) => {
          return <PostItem key={post.CommentID} index={post.CommentID} postData={post} user={user} addPost={addPost} config={props.config} params={props.params} wall={props.wall}/>
            }
          )}

       </InfiniteScroll>}
      
      {postList && postList.length==0 &&
        <div>{!props.params.qna?"No posts exist yet":"No questions exist yet"}</div>
      }
    </>
  )
}
else{
  return <div></div>
}
  
};


function mapStateToProps(state: {auth:any, social:any, session:any, settings:any}) {
  return {
      config: state.auth.config,
  };
}
function matchDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators(
    Object.assign({
      setSocialData:setSocialData,
    }),
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(PostList);
