import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import rootSaga from './sagas';
import createRootReducer from './reducers';

const createBrowserHistory = require('history').createBrowserHistory;

export const history = createBrowserHistory({ basename: '/app/' });

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [thunk, sagaMiddleware, routeMiddleware];

function configureStore(preloadedState) {
	let composeEnhancers = compose;
	if (process.env.NODE_ENV === 'development') {
		composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	}
	const store = createStore(
		createRootReducer(history), // root reducer with router state
		preloadedState,
		composeEnhancers(
			applyMiddleware(
				routerMiddleware(history), // for dispatching history actions
				...middlewares,
			),
		),
	);

	sagaMiddleware.run(rootSaga);
	return store;
}

export default configureStore();
