import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Button, Card, Col, Input, Modal, Row, Select, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { usePosters } from '../../hooks/usePosters';
import { useSettings } from '../../hooks/useSettings';
import { useTags } from '../../hooks/useTags';
import {
	DesignModes,
	SHOWCASE_MODE,
	TAKEOVER_MODE,
	KIOSK_MODE,
	STANDARD_MODE,
} from '../../../constants/ModesConfig';
import { updateDesignModes } from '../../../store/actions/designModes';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
// @ts-ignore
import { Link } from "react-router-dom";
const AdminPanel = ({ ...props }) => {
	const [isValidated, setIsValidated] = useState(false);
	const [filterTags, setFilterTags] = useState([]);
	const [filterTracks, setFilterTracks] = useState([]);
	const [timer, setTimer] = useState('');
	const [RotateTimer, setRotateTimer] = useState('');
	const [mode, setMode] = useState();
	const [settingsLoading, settings] = useSettings();
	const [postersLoading, posters] = usePosters();
	const [tagsLoading, tagsMap] = useTags();
	const getCurrentMode = useSelector(state => state.modes?.activeMode);
	const siteConfig = useSelector(state => state.modes?.siteConfig);
	const dispatch = useDispatch();
	const passwordRef = useRef(null);

	useEffect(() => {
		if (props?.openAdminPanel) {
			setTimeout(() => {
				if (passwordRef.current) passwordRef.current.focus();
			}, 10);
		}
	}, [props?.openAdminPanel]);

	const trackIdSet = useMemo(() => {
		if (postersLoading || settingsLoading) return [];
		else {
			const trackIdSet = new Set(posters.flatMap(p => p.trackId ?? []));
			let result = [];
			for (const trackId of trackIdSet) {
				result = [
					...result,
					{
						name: settings.trackMap.get(trackId)?.name?.trimEllip(50),
						count: posters.filter(p => p.trackId === trackId).length,
						id: trackId,
						type: 'tracks',
					},
				];
			}
			return result;
		}
	}, [postersLoading, posters, settings, settingsLoading]);

	const tagIdSet = useMemo(() => {
		if (postersLoading || tagsLoading) return [];
		else {
			const tagIdSet = new Set(posters.flatMap(p => p.tagIds ?? []));
			let result = [];
			for (const tagId of tagIdSet) {
				result = [
					...result,
					{
						name: tagsMap.get(tagId)?.name,
						count: posters.filter(p => p.tagIds.includes(tagId)).length,
						id: tagId,
						type: 'tags',
					},
				];
			}
			return result;
		}
	}, [postersLoading, posters, tagsMap, tagsLoading]);

	useEffect(() => {
		if (getCurrentMode?.tags?.length) {
			setFilterTags(getCurrentMode?.tags);
		}
		if (getCurrentMode?.tracks?.length) {
			setFilterTracks(getCurrentMode?.tracks);
		}
		setMode(getCurrentMode?.value);
		setTimer(
			Number(getCurrentMode?.timeout) > 0 ? getCurrentMode?.timeout : getTimeoutFromConfig(),
		);
		setRotateTimer(
			Number(getCurrentMode?.RotateTimer) > 0
				? getCurrentMode.RotateTimer
				: siteConfig?.showcase_mode?.rotation_time,
		);
	}, [getCurrentMode]);

	const handleChangeMode = e => setMode(e ?? 1);

	const currentMode = useMemo(() => DesignModes.find(item => item.value === mode), [mode]);

	useEffect(() => {
		const currentMode = DesignModes.find(item => item.value === mode);
		if (
			getCurrentMode &&
			getCurrentMode?.value === currentMode?.value &&
			Number(getCurrentMode?.timeout) > 0
		) {
			setTimer(getCurrentMode?.timeout);
			setRotateTimer(getCurrentMode.RotateTimer);
		} else {
			setTimer(getTimeoutFromConfig());
			setRotateTimer(siteConfig?.showcase_mode?.rotation_time);
		}
	}, [mode, getCurrentMode]);

	const handleFilterChange = data => e => {
		if (e.target.checked) {
			if (data?.type === 'tags')
				setFilterTags(oldVal => Array.from(new Set([...oldVal, data?.id])));
			else setFilterTracks(oldVal => Array.from(new Set([...oldVal, data?.id])));
		} else {
			if (data?.type === 'tags') setFilterTags(oldVal => oldVal.filter(e => e !== data?.id));
			else setFilterTracks(oldVal => oldVal.filter(e => e !== data?.id));
		}
	};

	const updateModesConfig = () => {
		const payload = {
			name: currentMode.label,
			value: currentMode.value,
			timeout: Number(timer) > 0 ? timer : getTimeoutFromConfig(),
			RotateTimer: Number(RotateTimer) > 0 ? RotateTimer : siteConfig?.showcase_mode?.rotation_time,
			tags: mode === TAKEOVER_MODE || mode === SHOWCASE_MODE ? filterTags : [],
			tracks: mode === TAKEOVER_MODE || mode === SHOWCASE_MODE ? filterTracks : [],
		};
		props.refine(props?.items);
		dispatch(updateDesignModes(payload));
		props.setOpenAdminPanel(false);
		localStorage.setItem('activeMode', JSON.stringify(payload))
		window.scrollTo({
			top: 10,
			behavior: 'smooth',
		});
	};

	const [form] = Form.useForm();

	const renderLogin = () => {
		return (
			<div>
				<Form
					className="gx-d-flex gx-align-items-center gx-flex-column"
					name="basic"
					onFinish={onFinish}
					validateTrigger="onSubmit"
					form={form}
				>
					<Form.Item
						style={{ width: '50%' }}
						name="password"
						rules={[
							{
								required: true,
								whitespace: true,
								message: 'Password cannot be empty',
							},
						]}
					>
						<Input.Password placeholder="Enter pin" ref={passwordRef} />
					</Form.Item>
					<Form.Item>
						<Button className="bg-primary-dark filterBtn" htmlType="submit">
							Continue
						</Button>
					</Form.Item>
				</Form>
			</div>
		);
	};

	const renderCustomFilters = (array = [], title, filterArray = []) => {
		return (
			<>
				<h3 className="gx-mb-2">{title}</h3>
				<hr className="gx-mt-0 gx-mb-2" />
				<div className="ais-RefinementList gx-algolia-refinementList gx-mb-4">
					<ul className="ais-RefinementList-list">
						{array
							.sort((a, b) => b?.count - a?.count)
							.map((e, index) => {
								return (
									<li className="ais-RefinementList-item" key={index}>
										<label className="ais-RefinementList-label">
											<input
												className="ais-RefinementList-checkbox"
												type="checkbox"
												onChange={handleFilterChange(e)}
												checked={filterArray?.find(data => data === e?.id)}
											/>
											<span
												className="ais-RefinementList-labelText wrapWord"
												title={e?.name ? e?.name : e?.id}
											>
												{e?.name ? e?.name : e?.id}
											</span>
											<span className="ais-RefinementList-count">
												{e?.count}
											</span>
										</label>
									</li>
								);
							})}
					</ul>
				</div>
			</>
		);
	};

	const renderConfiguration = () => {
		return (
			<>
				<u className="gx-mt-1" style={{ fontWeight: 'medium' }}>
					Configuration - {currentMode?.label}
				</u>
				{mode === SHOWCASE_MODE ? (
					<div className="gx-d-flex gx-align-items-center gx-mt-3">
						<label>Auto Rotate Interval</label>
						<Input
							style={{ width: 90 }}
							className="gx-ml-3"
							type="number"
							value={RotateTimer}
							onChange={e => setRotateTimer(e?.target?.value)}
						/>
						<label className="gx-ml-2">Seconds</label>
					</div>
				) : null}
				<div className="gx-d-flex gx-align-items-center gx-mt-3">
					<label>Inactivity Redirect Interval</label>
					<Input
						style={{ width: 90 }}
						className="gx-ml-3"
						type="number"
						value={timer}
						onChange={e => setTimer(e?.target?.value)}
					/>
					<label className="gx-ml-2">Seconds</label>
				</div>
				{mode === TAKEOVER_MODE || mode === SHOWCASE_MODE ? (
					<div className="gx-mt-2 gx-mb-2">
						<b className="text-select">Select Filter</b>
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							{trackIdSet.length ? (
								<Card style={{ width: '49%', marginTop: '10px' }}>
									<main>
										{renderCustomFilters(trackIdSet, 'Tracks', filterTracks)}
									</main>
								</Card>
							) : null}
							{tagIdSet.length ? (
								<Card style={{ width: '49%', marginTop: '10px' }}>
									<main>{renderCustomFilters(tagIdSet, 'Tags', filterTags)}</main>
								</Card>
							) : null}
						</div>
					</div>
				) : null}
				<div className="gx-mt-2">
					<Link key={'listListLink'} onClick={()=>{props.initiateSessionList()}}>
						Poster List
					</Link>
				</div>
			</>
		);
	};

	const rendermodes = () => {
		return (
			<>
				<div className="gx-d-flex gx-flex-column">
					<p className="gx-mb-4">
						<span>Current Mode </span>
						<b style={{ marginLeft: '25px', color: '#313A45' }}>
							{getCurrentMode?.name ?? KIOSK_MODE}
						</b>
					</p>
					<div className="gx-d-flex gx-align-items-center">
						<label>Select Mode</label>
						<Select
							className="select-dropdown"
							defaultValue={mode}
							style={{ width: 140 }}
							onChange={e => handleChangeMode(e)}
							options={DesignModes?.filter(e => isModeEnabled(e.value))}
						/>
					</div>
				</div>
				<div
					className="gx-d-flex gx-flex-column gx-mt-4"
					style={{ backgroundColor: '#ffffff' }}
				>
					{mode !== STANDARD_MODE ? renderConfiguration() : null}
					<Button
						style={{ width: 100, alignSelf: 'center' }}
						className="gx-mt-2 bg-primary-dark filterBtn"
						onClick={updateModesConfig}
					>
						Save
					</Button>
				</div>
			</>
		);
	};

	const onFinish = values => {
		if (values.password?.trim()?.toString() !== siteConfig?.admin_pin?.toString()) {
			form.setFields([
				{
					name: 'password',
					errors: ['Invalid pin'],
				},
			]);
			return;
		}

		if (getCurrentMode?.value === SHOWCASE_MODE) {
			const payload = {
				name: DesignModes.find(e => e?.value === STANDARD_MODE).label,
				value: STANDARD_MODE,
				tags: [],
				tracks: [],
				timeout: 0,
			}
			dispatch(
				updateDesignModes(payload),
			);
			localStorage.setItem('activeMode', JSON.stringify(payload))
		} else {
			setIsValidated(true);
		}
	};

	const getTimeoutFromConfig = () => {
		const current = siteConfig?.[DesignModes?.find(e => e.value === mode)?.value] ?? undefined;
		return current?.inactivity_time
			? current?.inactivity_time
			: 0;
	};

	const isModeEnabled = key => {
		return key === STANDARD_MODE ? true : siteConfig?.[key]?.enabled ?? false;
	};

	return (
		<Modal
			title={props?.title}
			visible={props?.openAdminPanel}
			onCancel={() => props?.setOpenAdminPanel(false)}
			footer={null}
			centered
			width={1000}
			className="adminPanel"
		>
			<Row>
				<Col span={24}>{!isValidated ? renderLogin() : rendermodes()}</Col>
			</Row>
		</Modal>
	);
};

export default connectCurrentRefinements(AdminPanel);
