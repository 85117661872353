import React, {useEffect, useState} from "react";
import {Button, Input, message, Form, Modal} from "antd";
import TextArea from 'antd/lib/input/TextArea';
import {NotificationContainer, NotificationManager} from "react-notifications";

const Report =(props)=> {
  
  const [formLayout, setFormLayout] = useState('vertical');
 
  const handleOk = values => {
    const urlid = window.location.href.split('/')[4];
    let params = {
      source: props.config.source_hex,
      conferenceid: props.config.conferenceid,
      commentid: props.post.CommentID,
      uuid_post: props.post.author.id,
      uuid:props.user.uuid,
      flag_reason:values,
      method:"social_flagging"
    }
    fetch("https://www.xcdsystem.com/masterapp_summer2012/controllers/mobileapp/api/index.cfm",{
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params),
      })
      .then(res => res.json())
      .then((response) => {
        if(response.success){
          NotificationManager.success(response.message, response.header);
        }
      })
    props.setVisible(false);
  };
  const handleCancel = () => {
    props.setVisible(false);
  };

    return(
      <div className="gx-app-login-wrap">
        
              <Modal
                visible={props.visible}
                title="Report an Issue"
                onCancel={handleCancel}
                footer={null}
                    >
                <div>
                  <Button style={{width:'100%'}} key="offensive" value="offensive" onClick={()=>handleOk('offensive')}>This post is offensive</Button>
                </div>
                <div>
                  <Button style={{width:'100%'}} key="spam" value="spam" onClick={()=>handleOk('spam')}>This post is commercial or spam</Button>
                </div>
                
                
              </Modal>
              <NotificationContainer/>
      </div>
    )
   
  };

  export default Report;
