import React from 'react';

import { Viewer, Worker } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import './index.css';

const PDFViewer = ({ url }) => {
	const zoomPluginInstance = zoomPlugin();
	const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
	const fullScreenPluginInstance = fullScreenPlugin();
	const { EnterFullScreenButton } = fullScreenPluginInstance;

	return (
		<Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
			<div className="gx-p-2 gx-d-flex gx-align-items-center gx-bg-light gx-border-bottom gx-border-dark">
				<ZoomOutButton />
				<ZoomPopover />
				<ZoomInButton />
				<div className="gx-ml-auto">
					<EnterFullScreenButton />
				</div>
			</div>
			<div
				className="pdf-viewer"
				style={{
					overflow: 'hidden',
					height: '80vh',
				}}
			>
				<Viewer fileUrl={url} plugins={[zoomPluginInstance, fullScreenPluginInstance]} />
			</div>
		</Worker>
	);
};

export default PDFViewer;
