// Customizer const(Settings)
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';
export const SESSION_SUGGESTIONS_PANEL_COLLAPSED = "SESSION_SUGGESTIONS_PANEL_COLLAPSED";
export const ATTENDEE_SUGGESTIONS_PANEL_COLLAPSED = "ATTENDEE_SUGGESTIONS_PANEL_COLLAPSED";
export const EXHIBITOR_SUGGESTIONS_PANEL_COLLAPSED = "EXHIBITOR_SUGGESTIONS_PANEL_COLLAPSED";
export const FIRST_LOAD = 'FIRST_LOAD';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const SIGNIN_USER_EMAIL = 'SIGNIN_USER_EMAIL';
export const USER_CREDIT_GROUP_SELECTED = 'USER_CREDIT_GROUP_SELECTED';

//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE='NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS='FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS='UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS='UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS='DELETE_CONTACT_SUCCESS';

//sessions
export const GET_ALL_SESSION = 'GET_ALL_SESSIONS';
export const GET_ALL_SESSION_SUCCESS  = 'GET_ALL_SESSION_SUCCESS';
export const SESSION_WEIGHTS_UPDATED = 'SESSION_WEIGHTS_UPDATED';
export const ATTENDEE_NORMALIZED_SCORE_UPDATED = "ATTENDEE_NORMALIZED_SCORE_UPDATED";
export const USER_TAGS_UPDATED = "USER_TAGS_UPDATED";
export const SET_OFFICE_HOUR_STATUS = "SET_OFFICE_HOUR_STATUS";

//evaluation
export const GET_CREDIT_TYPES = "GET_CREDIT_TYPES";
export const GET_CREDIT_TYPES_SUCCESS = "GET_CREDIT_TYPES_SUCCESS";
export const SUBMIT_CREDIT_TYPES = "SUBMIT_CREDIT_TYPES";
export const SUBMIT_CREDIT_TYPES_SUCCESS = "SUBMIT_CREDIT_TYPES_SUCCESS";

//exhibTable
export const REQUEST_EXHIB_DATA = "REQUEST_EXHIB_DATA";
export const RECIEVE_EXHIB_DATA = "RECIEVE_EXHIB_DATA";
export const REQUEST_EXHIB_FILTERS = "REQUEST_EXHIB_FILTERS";
export const RECIEVE_EXHIB_FILTERS = "RECIEVE_EXHIB_FILTERS";
export const REQUEST_ADDITIONAL_EXHIB_DATA = "REQUEST_ADDITIONAL_EXHIB_DATA";
export const RECIEVE_ADDITIONAL_EXHIB_DATA = "RECIEVE_ADDITIONAL_EXHIB_DATA";
export const RECIEVE_EXHIB_TAGS = "RECIEVE_EXHIB_TAGS";

//socialfeed
export const REQUEST_ALL_POSTS = 'REQUEST_ALL_POSTS';
export const RECIEVE_ALL_POSTS  = 'RECIEVE_ALL_POSTS';
export const PUSH_POST_SOCIAL  = 'PUSH_POST_SOCIAL';
export const RECIEVE_PUSH_POST  = 'RECIEVE_PUSH_POST';


//products
export const REQUEST_PRODUCT_DATA = "REQUEST_PRODUCT_DATA";
export const RECIEVE_PRODUCT_DATA = "RECIEVE_PRODUCT_DATA";

//favorites
export const FETCH_FAVORITES = "FETCH_FAVORITES";
export const MARK_FAVORITE = "MARK_FAVORITE";
export const UNMARK_FAVORITE = "UNMARK_FAVORITE";
export const FAVORITES_FETCHED = "FAVORITES_FETCHED";
export const MARK_FAVORITE_ACTION = 'MARK_FAVORITE_ACTION';
export const UNMARK_FAVORITE_ACTION = 'UNMARK_FAVORITE_ACTION';

//ratings
export const FETCH_RATINGS = "FETCH_RATINGS";
export const MARK_RATING = "MARK_RATING";
export const UNMARK_RATING = "UNMARK_RATING";
export const RATINGS_FETCHED = "RATINGS_FETCHED";
export const MARK_RATING_ACTION = 'MARK_RATING_ACTION';
export const UNMARK_RATING_ACTION = 'UNMARK_RATING_ACTION';

//posts
export const PUSH_POST = 'PUSH_POST';
export const CREATE_POST = 'CREATE_POST';
export const UPDATE_POST = 'UPDATE_POST';
export const DELETE_POST = 'DELETE_POST';
export const FETCH_POSTS = 'FETCH_POSTS';
export const SAVE_POST_ERROR = 'SAVE_POST_ERROR';
export const SET_POST_ERROR = 'SET_POST_ERROR';

export const FETCH_EXHIBITORS_SUCCESS = 'FETCH_EXHIBITORS_SUCCESS';
// Timeslots
export const FETCH_FREE_TIMESLOTS = "FETCH_FREE_TIMESLOTS";
export const FREE_TIMESLOTS_FETCHED = "FREE_TIMESLOTS_FETCHED";
export const REMOVE_TIMESLOT= "REMOVE_TIMESLOT";
export const REMOVE_TIMESLOT_SUCCESS= "REMOVE_TIMESLOT_SUCCESS";
export const FETCH_ALL_TIMESLOTS = "FETCH_ALL_TIMESLOTS";
export const ALL_TIMESLOTS_FETCHED = "ALL_TIMESLOTS_FETCHED";


// Message
export const RECIEVE_MESSAGE = 'RECIEVE_MESSAGE';
export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const PUT_MESSASGES = 'PUT_MESSAGES';


// Conversation
export const FETCH_CONVERSATIONS = 'FETCH_CONVERSATIONS';
export const RECIEVE_CONVERSATIONS = 'RECIEVE_CONVERSATIONS';
export const PUSH_NACK = 'PUSH_NACK';
export const CLEAR_NACK = 'CLEAR_NACK';
export const PUT_CONVERSATION = 'PUT_CONVERSATION';
export const PUT_NACK = 'PUT_NACK';


// UserStatus
export const PUSH_STATUSES = 'PUSH_STATUSES';
export const UPDATE_STATUS = 'UPDATE_STATUS';

// MyItinerary
export const FETCH_FAVORITE_SESSIONS = "FETCH_FAVORITE_SESSIONS";
export const FAVORITE_SESSIONS_FETCHED = "FAVORITE_SESSIONS_FETCHED";
export const ADD_TO_CALENDAR = "ADD_TO_CALENDAR";

// Meetings
export const FETCH_MEETINGS = "FETCH_MEETINGS";
export const MEETINGS_FETCHED = "MEETINGS_FETCHED";
export const ADD_MEETING = "ADD_MEETING";
export const MEETING_ADDED = "MEETING_ADDED";
export const REMOVE_MEETING = "REMOVE_MEETING";
export const MEETING_REMOVED = "MEETING_REMOVED";

//Tags
export const FETCH_TAGS = 'FETCH_TAGS';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_CATEGORIES = 'FETCH_TAGS_CATEGORIES';
export const FETCH_TAGS_CATEGORIES_SUCCESS = 'FETCH_TAGS_CATEGORIES_SUCCESS';
// Game
export const FETCH_GAME_TASKS = "FETCH_GAME_TASKS";
export const GAME_TASKS_FETCHED = "GAME_TASKS_FETCHED";
export const FETCH_GAME_CONFIGURATIONS = "FETCH_GAME_CONFIGURATIONS";
export const GAME_CONFIGURATIONS_FETCHED = "GAME_CONFIGURATIONS_FETCHED";
export const CHECK_AND_CALL_PYTHON_WORKER = "CHECK_AND_CALL_PYTHON_WORKER";
export const PYTHON_WORKER_CALLED = "PYTHON_WORKER_CALLED";
export const FETCH_GAME_TASKS_COMPLETED = "FETCH_GAME_TASKS_COMPLETED";
export const GAME_TASKS_COMPLETED_FETCHED = "GAME_TASKS_COMPLETED_FETCHED";
export const FETCH_LEADERBOARD = "FETCH_LEADERBOARD";
export const LEADERBOARD_FETCHED = "LEADERBOARD_FETCHED";

//Ads
export const SET_ADS = 'SET_ADS';
export const PUSH_ADS = 'PUSH_ADS';
export const LOG_ADS = 'LOG_ADS';
