import React, { useMemo, useEffect, useState, useRef } from 'react';
import { usePosters } from '../../hooks/usePosters';
import { useSelector } from 'react-redux';
import { Image, Modal, Progress, Space } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { ReactComponent as Play } from 'assets/icons/play.svg';
import { ReactComponent as Pause } from 'assets/icons/pause.svg';
import AdminPanel from '../KioskMode/AdminPanel';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const ShowCaseView = () => {
	const [postersLoading, posters] = usePosters();
	const [play, setPlay] = useState(true);
	const [isModalOpen, setIsModelOpen] = useState(false);
	const [openAdminPanel, setOpenAdminPanel] = useState(false);
	const [counter, setCounter] = useState(0);
	const [progressBar, setProgressBar] = useState(0);
	const timerRef = useRef(null);
	const playTimerRef = useRef(null);
	const getCurrentMode = useSelector(state => state.modes?.activeMode);
	const [countDown, setCountDown] = useState(0);

	useEffect(() => {
		if (!play && !isModalOpen) inactiveTimer(Number(getCurrentMode?.timeout)*1000 , true, false);
		else if (isModalOpen && !play) inactiveTimer(5000, false, true);
		return () => clearInterval(playTimerRef.current);
	}, [play, isModalOpen]);

	const inactiveTimer = (Seconds, modelBool, playBool) => {
		let idleTime = 0;
		document.addEventListener('mousemove', resetIdleTime, false);
		document.addEventListener('keypress', resetIdleTime, false);
		document.addEventListener('touchstart', resetIdleTime, false);
		function resetIdleTime() {
			idleTime = 0;
		}
		function checkIfIdle() {
			idleTime += 1000;
			if (idleTime >= Seconds) {
				setPlay(playBool);
				setIsModelOpen(modelBool);
			}
		}
		playTimerRef.current = setInterval(checkIfIdle, 1000);
	};

	const currentHits = useMemo(() => {
		const filtered = posters.filter(item => {
			return (
				getCurrentMode?.tags.some(tag => item?.tagIds?.includes(tag)) ||
				getCurrentMode?.tracks?.includes(item?.trackId)
			);
		});
		if (!filtered.length) return posters;
		return filtered;
	}, [getCurrentMode, posters]);

	useEffect(() => {
		if (play) {
				timerRef.current = setTimeout(() => {
						setCountDown(prev => prev + 1);
				}, 1000);
		}
		return () => {
				clearTimeout(timerRef.current)
		}
}, [countDown, play])


useEffect(() => {
		let progressTimeOut = Math.floor((countDown / Number(getCurrentMode?.RotateTimer)) * 100)
		setProgressBar(progressTimeOut);
		if (progressTimeOut >= 100) {
				setTimeout(() => {
						setCounter(prev => (prev == currentHits.length - 1 ? 0 : prev + 1));
						setCountDown(0);
				}, 500)
		}
}, [countDown])

	return (
		<>
			<div className="conformationWrapper">
				<Modal
					bodyStyle={{ paddingTop: 8, paddingBottom: 0 }}
					title="Confirmation"
					visible={isModalOpen}
					onCancel={() => setIsModelOpen(false)}
					okText={'Yes'}
					cancelButtonProps={{ style: { display: 'none' } }}
					onOk={() => {
						setIsModelOpen(false);
						setPlay(false);
					}}
					closable={false}
				>
					<p>Are you there?</p>
				</Modal>
			</div>
			<section className="showCase-container">
				<div className="imageWrapper">
					<TransformWrapper defaultPositionX={0}>
						<TransformComponent style={{ width: '100%' }}>
						{currentHits.map((item, index) => (
                                <Image
                                    src={
                                        item?.image ||
                                        require('assets/images/placeholder.png')
                                    }
                                    alt="poster image"
                                    className="gx-pointer"
                                    preview={false}
                                    style={{ width: '100%', display: index == counter ? 'block' : 'none' }}
                                />
                            ))}
						</TransformComponent>
					</TransformWrapper>
				</div>
				<div style={{ background: '#1A1A1A' }}>
					<div className="alignIcons">
						<HomeOutlined
							style={{ fontSize: '29px', color: '#919191' }}
							onClick={() => setOpenAdminPanel(true)}
						/>
						<Space>
							<Progress
								type="circle"
								percent={progressBar}
								width={59}
								strokeWidth={8}
								trailColor={'#919191'}
								strokeColor={progressBar > 99 ? '#52c41a' : 'red'}
								format={() => (
									<span onClick={() => setPlay(prev => !prev)}>
										{play ? (
											<Pause
												style={{
													width: '2rem',
													height: '2rem',
													cursor: 'pointer',
													border: 0,
													marginRight: 3,
												}}
												title="Pause"
												fill="#919191"
											/>
										) : (
											<Play
												style={{
													width: '1.5rem',
													height: '2rem',
													cursor: 'pointer',
													border: 0,
													marginLeft: 2,
												}}
												title="Play"
												fill="#919191"
											/>
										)}
									</span>
								)}
							/>
						</Space>
					</div>
				</div>
				{openAdminPanel ? (
					<AdminPanel
						openAdminPanel={openAdminPanel}
						setOpenAdminPanel={arg => setOpenAdminPanel(arg)}
						title="Admin Control Panel"
					/>
				) : null}
			</section>
		</>
	);
};

export default ShowCaseView;
