import Jitsi from './Jitsi'

import React from 'react';
// @ts-ignore
import { useDispatch, useSelector } from "react-redux";


const moment = require("moment");
function JitsiContainer(props: any) {
    const { config } = useSelector((state: any) => state.auth);

    return <Jitsi {...props} />;
}

export default JitsiContainer;
