import { useEffect, useState } from 'react';

export default function useDocumentWidth() {
  const [documentWidth, setDocumentWidth] = useState(document.documentElement.clientWidth);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const callback = () => { setDocumentWidth(document.documentElement.clientWidth) };
    window.addEventListener('resize', callback);
    return () => window.removeEventListener('resize', callback);
  }, [])
  useEffect(() => {
    if (documentWidth <= 768) { setIsMobile(true) }
    else if (isMobile) { setIsMobile(false) }
  }, [documentWidth])
  return { isMobile }
}
