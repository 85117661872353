import React, { useContext } from 'react';

import { connectStateResults } from 'react-instantsearch-dom';
import { Card, Skeleton, Image, Empty, Tag } from 'antd';
import { useSelector } from 'react-redux';

import PostersView from '../PostersView';
import { ViewContext } from '../ViewContext';
import { VIEW } from 'constants.js';
import { SHOWCASE_MODE, TAKEOVER_MODE } from '../../../constants/ModesConfig';
import { useSettings } from '../../hooks/useSettings';
import { useTags } from '../../hooks/useTags';
import useDocumentWidth from '../../hooks/useDocumentWidth';

const StateResults = ({ searchResults, loading, renderHeder }) => {
	const { view } = useContext(ViewContext);
	const getCurrentMode = useSelector(state => state.modes?.activeMode);
	const [settingsLoading, settings] = useSettings();
	const [tagsLoading, tagsMap] = useTags();
	const { isMobile } = useDocumentWidth();

	const searchLoading = loading || !searchResults;

	if (searchLoading)
		return (
			<section className="poster-grid gx-mt-4">
				{[...Array(8)].map((_, index) => (
					<Card key={index} className="gx-mb-0 -shadow-1">
						<Image
							className="card-image"
							style={{ height: '7em' }}
							alt="placeholder"
							src={require('assets/images/placeholder.png')}
						/>
						<Skeleton active paragraph={{ rows: 5 }}></Skeleton>
					</Card>
				))}
			</section>
		);
	if (searchResults.nbHits === 0) return <Empty style={{ margin: '20em 0' }} />;

	const renderFiltered = (title, filterArray = [], matchObject) => {
		return (getCurrentMode?.value === SHOWCASE_MODE ||
			getCurrentMode?.value === TAKEOVER_MODE) &&
			filterArray?.length ? (
			<div className="gx-mb-3">
				{title}:{' '}
				{filterArray?.map((e, index) => (
					<span
						key={index}
						className={`ant-tag ant-tag-has-color gx-mb-0 ${
							title !== 'Tracks' ? 'staticTagColor' : ''
						}`}
						style={{ backgroundColor: title === 'Tracks' ? settings.trackMap.get(e)?.color === '#' ? settings.primary : settings.trackMap.get(e)?.color : undefined}}
					>
						{' '}
						{matchObject?.get(e)?.name.trimEllip(50)}
					</span>
				))}
			</div>
		) : null;
	};

	return (
		<>
			{view !== VIEW.QUICK && (
				<>
					{
						isMobile ? 
						<p>
							Displaying  {searchResults.hits.length} of {searchResults.nbHits} posters
						</p> : 
						<div className='gx-d-flex ant-space-align-baseline'>
							<div style={{ width: getCurrentMode?.value !== SHOWCASE_MODE ? '85%' : '75%' }}>
								Displaying  {searchResults.hits.length} of {searchResults.nbHits} posters
							</div>
							<div>
								{renderHeder()}
							</div>
						</div>
					}
					{renderFiltered('Tracks', getCurrentMode?.tracks, settings.trackMap)}
					{renderFiltered('Tags', getCurrentMode?.tags, tagsMap)}
				</>
			)}
			<PostersView posters={searchResults.hits} />
		</>
	);
};

export default connectStateResults(StateResults);
