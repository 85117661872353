import { RECEIVE_TAGS } from '../actions/tags';

const initialState = {
	tagsMap: new Map(),
	fetched: false,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case RECEIVE_TAGS: {
			const tags = payload.data.map(tag => ({
				id: tag.id,
				name: tag.label,
				categoryId: tag.catid,
				type: tag.type,
			}));
			const tagsMap = new Map(tags.map(tag => [tag.id, tag]));

			return { ...state, tagsMap, fetched: true };
		}
		default:
			return state;
	}
};
