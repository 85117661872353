import React from 'react';
import { Button } from 'antd';
const PosterButton = ({title, Icon, className, click=()=>{} }) => {
    return (
        <Button
            onClick={click}
            className={className ? className : "btn-primary"}
            icon={<Icon />}
        >
            {title}
        </Button>
    )
}

export default PosterButton