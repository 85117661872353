import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSettings } from '../hooks/useSettings';

import { ReactComponent as Lines } from 'assets/graphics/lines.svg';

import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import useDocumentWidth from '../hooks/useDocumentWidth';

const Header = ({ children, showBack = false }) => {
	const history = useHistory();
	const [, settings] = useSettings();
	const { isMobile } = useDocumentWidth();
	const renderBackButton = () => (
		<Button
			onClick={() => history.action === 'PUSH' ? history.goBack() : history.push('../main')}
			className="btn-primary"
			icon={<ArrowLeftOutlined />}
		>
			Back to Posters
		</Button>
	)
	return (
		<header className="bg-primary">
			{!isMobile ? <>{/* <div className="gx-p-2 gx-d-flex gx-container header-top">
				{showBack && (renderBackButton())}
				<Lines
					className="gx-ml-auto gx-my-auto gx-d-none gx-d-lg-block"
					style={{ width: '16rem' }}
				></Lines>
			</div> */}
				<div className="bg-primary-dark header-band">
					<div
						className="bg-primary-dark gx-p-2 gx-d-lg-flex gx-container"
						style={{ overflow: 'visible', height: '5.5em' }}
					>
						<div className="main-banner">
							<img
								src={settings?.bannerWide}
								// onError={e => (e.target.src = settings.banner)} // Created flickering issue
								className="-rounded shadow-2"
								alt=''
							/>
						</div>

						<div style={{marginLeft: "30%"}}><img src="https://s3.amazonaws.com/xcdreact/xcd_eposter.png"
						style={{background: "#fff", height: "4em"}}
						/></div>
						{children}
					</div>
				</div> </> : <div>
				<img
					src={settings?.bannerWide}
					// onError={e => (e.target.src = settings.banner)}
					className="-rounded gx-w-100"
					alt=''
				/>
				<div
					className='bg-primary-dark gx-p-2 header-band gx-container'
					style={{ overflow: 'visible', height: '4.5em', width: '100%' }}
				>
					{/* {showBack && (<div className='gx-mt-2'>{renderBackButton()}</div>)} */}
					{children}
				</div>
			</div>
			}
		</header>
	);
};

export default Header;
