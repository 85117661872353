import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router-dom';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './store';

import App from './app.jsx';
import socketIOClient from "socket.io-client";

function generateUUID() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
	  var r = (Math.random() * 16) | 0,
		v = c === 'x' ? r : (r & 0x3) | 0x8;
	  return v.toString(16);
	});
  }



window.socket = socketIOClient(
	`https://applevelsocket.econference.io?room=poster_app&uuid=${generateUUID()}&ipAddress=127.0.0.1`,
	{ transports: ["websocket", "polling"], reconnection: true }
  );
  window.socket.on(
	`poster_app`,
	(data) => {
		if(data.type == "kill_switch") {
            window.location.reload();
          }
	});


ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<Switch>
				<Route path="/:appDir/:urlId" component={App} />
			</Switch>
		</ConnectedRouter>
	</Provider>,
	document.getElementById('root'),
);
