import React, {useState} from "react";
import {Avatar, Button, Card, Divider, Input, Modal, Upload, Progress} from "antd";
import Icon from '@ant-design/icons';
import {handleImageCompression} from "../wall";
import axios from "axios";

const {TextArea} = Input;

interface Props {
  addPost:any,
  config:any,
  user:{Biography: string,
    City: string,
    Company: string,
    ContactTitle: string,
    Country: string,
    CustID: number,
    DateAdded: string,
    Email: string,
    Firstname: string,
    Lastname: string,
    Phone: string,
    Picture: string,
    ReviewID: number,
    SourceHexID: string,
    State: string,
    Zip: string,
    uuid: string,
    Degrees:string,
    Prefix:string,
    CustomField_3:string,
    CustomField_4:string},
  params:{
    topic_identifier:string,
    pageType:string, 
    NewPostTags:string[], 
    appliedFilters:string[], 
    DisplayRelatedTags:string[], 
    ads:Boolean,
    key:string,
    qna?:boolean,
    author?: any
  }
}

const WriteBox = (props: Props) => {
  let author = props.params.author && props.params.author.length > 0?props.params.author[0]:{};


  const [commentText, setCommentText] = useState('');


  const [fileList, setFileList] = useState([] as any);

  const [isOpen, setIsOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [key, setKey] = useState(0);

  const handleClickImage = () => {
    setIsOpen(!isOpen);
  };

  const uploadImage = async (options: { onSuccess: any; onError: any; file: any; onProgress: any; }) => {
    const { onSuccess, onError, file, onProgress } = options;
    handleImageCompression(file).then((img) =>{
        let baseFile = img;
       var reader = new FileReader();
        reader.onloadend = async function() {
          //console.log('RESULT', reader.result)
          baseFile = reader.result;
          baseFile = baseFile.replace('data:image/png;base64,','').replace('data:image/jpeg;base64,','');
             
              const configPost = {
                headers: { "content-type": "image/png" },
                onUploadProgress: (event: { loaded: number; total: number; }) => {
                  const percent = Math.floor((event.loaded / event.total) * 100);
                  setProgress(percent);
                  if (percent === 100) {
                    setTimeout(() => setProgress(0), 1000);
                  }
                  onProgress({ percent: (event.loaded / event.total) * 100 });
                }
              };
              
              try {
                const res = await axios.post(
                  "https://cors.econference.io/https://yynpf1g9s6.execute-api.us-east-1.amazonaws.com/Prod/api/file/upload/" + props.config.source_hex + "/social",
                  baseFile,
                  configPost
                );
                console.log("server res: ", res);
                  let imgObj = {
                      file_url: res.data.url,
                      caption:"image",
                      uid:res.data.url,
                      url:res.data.url
                    }
                  let ImgArray:any = fileList;  
                  ImgArray.push(imgObj);
                  setFileList(ImgArray);
                  setKey(ImgArray.length)
              } catch (err) {
                console.log("Eroor: ", err);
              }
        }
        reader.readAsDataURL(baseFile);
    });
   
  };

  const handleOnChange = () => {
    // console.log(file, fileList, event);
    //Using Hooks to update the state to the current filelist
   // setDefaultFileList(fileList);
  };

  const handleAddPost = () => {
    props.addPost(commentText, fileList,null, props.config, props.params);
    setCommentText('');
    setFileList([]);
    setIsOpen(false);
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommentText(e.target.value)
  };

  const isEnabled = fileList.length === 0 && commentText === "";
  const uploadButton = (
    <div>
      <i className="icon icon-add gx-mr-2 gx-fs-xl gx-d-inline-flex gx-vertical-align-middle"/>
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <Card className="social-card gx-mt-0" style={{marginLeft:5,marginRight:5}}>
      <div className="gx-media gx-mb-2">
        <Avatar className="gx-size-50 gx-mr-3" src={props.user.Picture?props.user.Picture.startsWith("http")?props.user.Picture:props.config.appurl+ "/pictures_mobile/" + props.user.Picture:"https://ui-avatars.com/api/?name=" + encodeURIComponent(props.user.Firstname + " " + props.user.Lastname) + "&size=128&background=" + props.config.primary_light_color.substr(1) + "&color=000"}/>
        <div className="gx-media-body">
          <TextArea className="gx-border-0"
                    id="exampleTextarea"
                    value={commentText}
                    rows={4}
                    onChange={(event) => onChange(event)}
                    placeholder={props.params.qna?`Ask question to ${!props.config?.attendee_name?.hide_prefix?author.Mr:''} ${author.Firstname} ${author.Lastname}`:"Add Conference Post"}/>
        </div>
      </div>

      <div className="gx-clearfix">
        {isOpen ? <Upload
          accept="image/*"
          customRequest={uploadImage}
          listType="picture-card"
          multiple={true}
          defaultFileList={fileList}
          onChange={handleOnChange}
          className="image-upload-grid"
          key={"upload:" + key}
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>:null}

        
      </div>
      <Divider/>

      <div className="ant-row-flex">
      {!props.params.qna && <div className="gx-pointer" onClick={handleClickImage}>
          <i className="icon icon-camera gx-mr-2 gx-fs-xl gx-d-inline-flex gx-vertical-align-middle"/>
          <span className="gx-fs-sm"> Add Photos/Album </span>
        </div>}

        <Button type="primary" size='small' className="gx-ml-auto gx-mb-0"
                disabled={(isEnabled) ? true : false}
                onClick={handleAddPost}>Post
        </Button>
      </div>
    </Card>
  )
};

export default WriteBox;
