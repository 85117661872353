import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { usePosters } from 'components/hooks/usePosters';
import { useTags } from '../hooks/useTags';
import { useUser } from '../hooks/useUser';
import Header from '../common/Header';
import Loading from 'components/common/Loading';

import TitleTab from './TitleTab';
import PresenterTab from './PresenterTab';
import SessionTab from './SessionTab';
import OrganizationTab from './OrganizationTab';

import { TAB, VIEW } from '../../constants';

import { ViewContext } from './ViewContext';
import { TabContext } from './TabContext';
import { UserContext } from './UserContext';
import { SearchContext } from './SearchContext';
import './index.css';
import TopNavigaton from './TopNavigaton';
import PosterButton from '../common/PosterButton';
import { KIOSK_MODE, SHOWCASE_MODE, TAKEOVER_MODE } from '../../constants/ModesConfig';
import KioskTab from './KioskMode';
import ShowCaseView from './ShowCaseMode';
import { useSettings } from '../hooks/useSettings';
import { updateDefaultSettings } from '../../store/actions/designModes';

const MainPage = () => {
	const location = useLocation();
	const history = useHistory();
	const [params, setParams] = useState(new URLSearchParams(location.search));
	const [tab, setTab] = useState(params.get('tab') || TAB.TITLE);
	const [view, setView] = useState(params.get('view') || VIEW.GRID);
	const [searchState, setSearchState] = useState({
		query: params.get('query') ?? '',
		page: parseInt(params.get('page')) || 1,
		refinementList: {
			tracks: (params.get('tracks') && params.get('tracks').split(',')) || [],
			tags: (params.get('tags') && params.get('tags').split(',')) || [],
			sessions: (params.get('sessions') && params.get('sessions').split(',')) || [],
		},
	});
	const { value } = useSelector(state => state.modes?.activeMode);
	const { siteConfig } = useSelector(state => state.modes?.siteConfig);
	const sessionPosters = useSelector(state => state.posters.sessionPosters);
	const [settingsLoading, settings] = useSettings();
	const dispatch = useDispatch();

	useEffect(() => {
		if (settings?.original?.poster_app && !siteConfig)
			dispatch(updateDefaultSettings(settings?.original?.poster_app));
	}, [settingsLoading, settings]);

	useEffect(() => history.push(`?${params}`), [params]);

	useEffect(() => {
		const newParams = new URLSearchParams(params);
		newParams.set('view', view);
		setParams(newParams);
	}, [view]);

	useEffect(() => {
		const newParams = new URLSearchParams(params);
		newParams.set('tab', tab);
		setParams(newParams);
	}, [tab]);

	useEffect(() => {
		const newParams = new URLSearchParams(params);
		newParams.set('query', searchState?.query ?? '');
		newParams.set('page', searchState?.page ?? 1);
		newParams.set('tracks', searchState.refinementList?.tracks ?? []);
		newParams.set('tags', searchState.refinementList?.tags ?? []);
		newParams.set('sessions', searchState.refinementList?.sessions ?? []);
		setParams(newParams);
	}, [searchState, value]);

	useEffect(() => {
		const resetView = () => {
			if (document.documentElement.clientWidth <= 768) {
				setView(VIEW.GRID);
			}
		};
		window.addEventListener('resize', resetView);
		return window.removeEventListener('resize', resetView);
	}, []);

	const [postersLoading] = usePosters();
	const [tagsLoading] = useTags();
	const [loginEnabled, userFetched, userObj] = useUser();

	if ((postersLoading || tagsLoading) && !(loginEnabled && userFetched)) return <Loading />;

	return (
		<UserContext.Provider value={{ loginEnabled, userFetched, userObj }}>
			<TabContext.Provider value={{ tab, setTab }}>
				<ViewContext.Provider value={{ view, setView }}>
					<SearchContext.Provider value={{ searchState, setSearchState }}>
						<main>
							{value !== SHOWCASE_MODE ? <Header /> : null}
							{/* <TopNavigaton tab={tab} setTab={setTab} sessionPosters={sessionPosters} /> */}

							{/* <TitleTab /> */}
							<KioskTab />
							{/* <ShowCaseView/>  */}
							{/* {tab === TAB.TITLE && <TitleTab />}
							{tab === TAB.PRESENTER && <PresenterTab />}
							{tab === TAB.SESSION && <SessionTab />}
							{tab === TAB.ORGANIZATION && <OrganizationTab />} */}
						</main>
					</SearchContext.Provider>
				</ViewContext.Provider>
			</TabContext.Provider>
		</UserContext.Provider>
	);
};

export default MainPage;
