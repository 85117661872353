import React, { useMemo, useContext, useState, useEffect } from 'react';
import Fuse from 'fuse.js';

import { usePosters } from 'components/hooks/usePosters';
import { useSettings } from 'components/hooks/useSettings';
import { SearchContext } from '../SearchContext';
import { ViewContext } from '../ViewContext';
import { VIEW } from 'constants.js';
import { useSelector, useDispatch } from 'react-redux';

import SearchBox from 'components/common/Algolia/SearchBox';
import Pagination from 'components/common/Algolia/Pagination';
import StateResults from './StateResults';

import { InstantSearch, Configure, RefinementList } from 'react-instantsearch-dom';
import { Card, Col, Row } from 'antd';
import useDrawer from '../../hooks/useDrawer';
import _isEmpty from 'lodash/isEmpty';
import { Button, Modal } from 'antd';
import { FilterFilled } from '@ant-design/icons';
import AdminPanel from './AdminPanel';
import { SHOWCASE_MODE, TAKEOVER_MODE } from '../../../constants/ModesConfig';
import ShowCaseView from '../ShowCaseMode';
import { ReactComponent as AdminPanelSettingsIcon } from '@material-design-icons/svg/filled/admin_panel_settings.svg';
import useDocumentWidth from '../../hooks/useDocumentWidth';
import ExploreMode from '../ExploreMode';
import { updateDesignModes } from '../../../store/actions/designModes';
import { List } from 'antd';
import _ from 'lodash';
const KioskTab = () => {
	const [postersLoading, posters, sessionPosters] = usePosters();
	const { searchState, setSearchState } = useContext(SearchContext);
	const { view } = useContext(ViewContext);
	const getCurrentMode = useSelector(state => state.modes?.activeMode);
	const siteConfig = useSelector(state => state.modes?.siteConfig);
	const { isMobile } = useDocumentWidth();
	const [showExplore, setShowExplore] = useState(false);
	const [sessionList, setSessionList] = useState(false);
	const [selectedSession, setSelectedSession] = useState(null);
	const [settingsLoading, settings] = useSettings();
	const dispatch = useDispatch();
	const searchFuse = useMemo(
		() =>
			postersLoading ||
			new Fuse(posters, {
				keys: [
					'title',
					'description',
					'authors.company',
					'authors.firstName',
					'authors.lastName',
				],
				useExtendedSearch: true,
				includeMatches: true,
			}),
		[postersLoading, posters],
	);

	const { trackIdSet, tagIdSet, sessionIdSet, filterPostersFromfacet } = useMemo(
		() => {
			const sessionIds = searchState?.refinementList?.sessions?.length ? posters?.filter(e=> e?.sessionId && searchState?.refinementList?.sessions?.includes(e?.sessionId?.toString()))?.map(e => e?.id) : [];

			const trackIds = searchState?.refinementList?.tracks?.length ? posters?.filter(e=> e?.trackId && searchState?.refinementList?.tracks?.includes(e?.trackId?.toString()))?.map(e => e?.id) : [];

			const tagIds = searchState?.refinementList?.tags?.length ? posters?.filter(e=> e?.tagIds && searchState?.refinementList?.tags?.filter(p => e?.tagIds?.includes(parseInt(p)))?.length > 0)?.map(e => e?.id) : [];

			const temp = [];

			if(sessionIds?.length > 0) {
				temp.push([...sessionIds])
			}
			if(trackIds?.length > 0) {
				temp.push([...trackIds])
			}
			if(tagIds?.length > 0) {
				temp.push([...tagIds])
			}

			const intersectArrays = (arrays) => {
				return arrays?.reduce((result, currentArray) => Array?.from(new Set(result))?.filter(element => currentArray?.includes(element)));
			}

			const filterPostersFromfacet = temp.length > 0 ? intersectArrays(temp) : [];
			const sessionIdSet =  postersLoading || new Set(_.uniq(posters?.map(p => p?.sessionId)))
			const trackIdSet =  postersLoading || new Set(_.uniq(posters?.map(p => p?.trackId)))
			const tagIdSet =  postersLoading || new Set(_.uniq(posters.flatMap(p => p.tagIds)))

			return {
				trackIdSet,
				tagIdSet,
				sessionIdSet,
				filterPostersFromfacet,
			}
		},
		[postersLoading, posters, searchState.refinementList],
	);

	const [isModalOpen, setIsModelOpen] = useState(false);
	const [openAdminPanel, setOpenAdminPanel] = useState(false);
	const { showDrawer, showSideBar, renderSiderBar, renderDrawer } = useDrawer({setIsModelOpen, filterPostersFromfacet});

	const filterCount = useMemo(() => {
		let count = 0;
		Object.entries(searchState?.refinementList ?? {}).forEach(([key, value]) => {
			count += value.length;
		});
		return count;
	}, [searchState.refinementList]);

	useEffect(() => {
		const getPrevioussConfig = localStorage.getItem('activeMode');
		if (getPrevioussConfig) {
			if (isValidJson(getPrevioussConfig)) {
				dispatch(updateDesignModes(JSON.parse(getPrevioussConfig)));
			}
		}
	}, []);

	const isValidJson = string => {
		try {
			JSON.parse(string);
			return true;
		} catch (error) {
			return false;
		}
	};

	const search = requests =>
		new Promise((resolve, reject) => {
			try {
			if (postersLoading) return reject();

			const { query, page, hitsPerPage, facetFilters = [] } = requests[0].params;

			const filteredPosters = query
				? searchFuse.search(`'"${query}"`).map(r => r.item)
				: posters;
			let totalHits = [];
			const facets = { tracks: {}, tags: {}, sessions: {} };
			if (
				getCurrentMode?.value === SHOWCASE_MODE ||
				getCurrentMode?.value === TAKEOVER_MODE
			) {
				if (getCurrentMode?.tracks?.length)
					facetFilters.push(getCurrentMode?.tracks?.map(e => `tracks:` + e));
				if (getCurrentMode?.tags?.length)
					facetFilters.push(getCurrentMode?.tags?.map(e => `tags:` + e));
				// if (getCurrentMode?.sessions?.length)
				// 	facetFilters.push(getCurrentMode?.sessions?.map(e => `sessions:` + e));
			}
			if (_isEmpty(facetFilters)) {
				totalHits = [...filteredPosters];
			}
			// facet filtering logic
			for (let i = 0; i < facetFilters.length; i++) {
				for (let k = 0; k < facetFilters[i].length; k++) {
					const [key, value] = facetFilters[i][k].split(':');
					const id = parseInt(value);
					switch (key) {
						case 'tracks':
							totalHits = [
								...totalHits,
								...filteredPosters.filter(p => p?.trackId && p.trackId === id && filterPostersFromfacet?.includes(p?.id)),
							];
							break;
						case 'sessions':
								totalHits = [
									...totalHits,
									...filteredPosters.filter(p => p?.sessionId && p?.sessionId === id && filterPostersFromfacet?.includes(p?.id)),
								];
								break;
						case 'tags': {
							totalHits = [
								...totalHits,
								...filteredPosters.filter(p => p?.tagIds && filterPostersFromfacet?.includes(p?.id) && p?.tagIds?.includes(id)),
							];
							break;
						}
						default:
							console.error('Invalid facet passed into algolia');
					}
				}
			}

			// Remove the duplicate posters if appended from the swtich case
			totalHits = _.uniqBy(totalHits, (e) => e.id);

			let sortFunction = (a, b) => (a.title > b.title ? 1 : -1);

			if(siteConfig.sort_by_presenter_name) {
				sortFunction = (a, b) => {
					let presenter_a = a.authors.find(author => author.original.presenter);
					let presenter_b = b.authors.find(author => author.original.presenter);
					if(presenter_a && presenter_b) {
							return presenter_a.lastName.localeCompare(presenter_b.lastName);
						} else {
							return 0;
					}
				};
			}
			
			totalHits.sort(sortFunction);

			for (const trackId of trackIdSet) {
				const count = filterTrack(trackId)?.length;
				if(count > 0) {
					facets.tracks[trackId] = count;
				}
			}

			for (const tagId of tagIdSet) {

				let filterPosters = [...posters?.filter(p => p?.tagIds && p.tagIds?.includes(tagId))];

				if(searchState?.refinementList?.sessions?.length) {
					filterPosters = [...filterPosters?.filter(p => p?.sessionId && searchState?.refinementList?.sessions.includes(p?.sessionId?.toString()))]
				}

				if(searchState?.refinementList?.tracks?.length) {
					filterPosters = [...filterPosters?.filter(p => p?.trackId && searchState?.refinementList?.tracks.includes(p?.trackId?.toString()))]
				}

				const count = _.uniqBy(filterPosters, (e)=> e?.id)?.length ?? 0

				if (count > 0) {
					facets.tags[tagId] = count;
				}
			}

			for (const sessionId of sessionIdSet) {
				let filterPosters = [...posters?.filter(p => p?.sessionId && p.sessionId === sessionId)];

				if(searchState?.refinementList?.tracks?.length) {
					filterPosters = [...filterPosters?.filter(p => p?.trackId && searchState?.refinementList?.tracks.includes(p?.trackId?.toString()))]
				}

				// if(searchState?.refinementList?.tags?.length) {
				// 	filterPosters = [...filterPosters?.filter(e=> e?.tagIds && searchState?.refinementList?.tags?.filter(p => e?.tagIds?.includes(parseInt(p)))?.length > 0)]
				// } 

				const count = _.uniqBy(filterPosters, (e)=> e?.id)?.length ?? 0

				if(count > 0) {
					facets.sessions[sessionId] = count;
				}
			}
            
			// pagination
			const nbHits = totalHits.length;
			const nbPages = Math.ceil(nbHits / hitsPerPage);
			const hits = totalHits.slice(page * hitsPerPage, page * hitsPerPage + hitsPerPage);

			resolve({ results: [{ hits, nbHits, page, nbPages, hitsPerPage, facets }] });
			}
			catch (error) {
                console.log(error);
            }

		});


	const filterTrack = (trackId, array = posters) => {
		return array?.filter(p => p?.trackId && p?.trackId?.toString() === trackId?.toString()) ?? []
	}

	const renderHeder = () => {
		return (
			<>
				{renderDrawer()}

				<div
					style={{ gap: 30 }}
					className={showSideBar && !showDrawer ? 'gx-d-flex ant-row-end searchBar' : ''}
				>
					{siteConfig?.enable_explore_mode? (
						<Button

							type="primary"
							onClick={() => setShowExplore(true)}
						>
							Explore using Word Cloud
						</Button>
					) : null}
					<SearchBox />
					{showSideBar &&
						!showDrawer &&
						getCurrentMode?.value !== SHOWCASE_MODE &&
						getCurrentMode?.value !== TAKEOVER_MODE && (
							<Button
								className="bg-primary-dark filterBtn"
								icon={<FilterFilled />}
								onClick={() => setIsModelOpen(true)}
							>
								Filter
								{filterCount ? (
									<span className="filterCount"> {filterCount}</span>
								) : null}
							</Button>
						)}
				</div>
			</>
		);
	};

	const renderFooter = () => {
		return (
			<Row justify="center" className="kioskPagination">
				<Col span={8}></Col>
				<Col span={8} style={{ textAlign: 'center' }}>
					<Pagination />
				</Col>
				<Col span={8} style={{ textAlign: 'right' }}>
					{siteConfig?.onsite_enabled ? (
						<Button
							className="admin-button"
							shape="rounded"
							type="primary"
							title="Admin Control Panel"
							onClick={() => setOpenAdminPanel(true)}
						>
							<AdminPanelSettingsIcon style={{ color: '#313A45' }} />
						</Button>
					) : null}
				</Col>
			</Row>
		);
	};

	const renderModalContent = () => {
		return (
			<div className="modelWrapper">
				<Modal
					width={'50%'}
					visible={isModalOpen}
					onCancel={() => setIsModelOpen(false)}
					footer={null}
					className="instantSearchModal"
				>
					<aside className="sideBarCard" style={{ flexBasis: '25rem' }}>
						<Card>{renderSiderBar()}</Card>
					</aside>
				</Modal>
			</div>
		);
	};

	const handleExplore = bool => {
		setShowExplore(bool);
	};

	if(sessionList) {
		return (<>
							<Button
								className="bg-primary-dark"

								onClick={() => {setSessionList(false); setSelectedSession(null);}}
							>
								Exit Session List
							</Button>
							<main className='gx-py-5 gx-my-5 gx-container gx-d-flex'>
							<Row>

							{!selectedSession && sessionPosters.map((posterSession) => {
								return (<Col span={24} key={posterSession.session.id + 'li'}><Button onClick={() => {setSelectedSession(posterSession)}}>
								{posterSession.session.title} - {settings.rooms[posterSession.session.room_id]}
							</Button></Col>);
							})}
							{selectedSession && <Button onClick={() => {setSelectedSession(null)}}>Back</Button>}
							{selectedSession && <Col span={24}><Card><h1>{selectedSession.session.title}  - {settings.rooms[selectedSession.session.room_id]}</h1></Card></Col>}
							{selectedSession && <Col span={24}><List itemLayout='horizontal'
							dataSource={selectedSession.posters.map((poster) => {
								return {'title': poster.title, 'presentor': poster.authors.map((author) => `${author.firstName} ${author.lastName}`).join(", ")}
								// return (<Col span={26} key={poster.id + 'li'}>
								// 	<Card>
								// 		<Col span={24}>
								// 		<h3>{poster.title}</h3>
								// 		</Col>
								// 		<Col span={24}>
								// 			{poster.authors[0].firstName} {poster.authors[0].lastName}
								// 		</Col>
								// 	</Card>
								// </Col>);
							})}
							renderItem={(item, index) => (
								<List.Item>
								<List.Item.Meta
								  title={<h3>{item.title}</h3>}
								  description={<h4>{item.presentor}</h4>}
								/>
							  </List.Item>)
							}
							/>
							</Col>
							}
							</Row>
							</main>

		</>)
	}

	return (
		<InstantSearch
			indexName="posters/selection/title"
			searchClient={{ search }}
			searchState={searchState}
			onSearchStateChange={setSearchState}
		>
			{showExplore ? (
				<ExploreMode handleExplore={handleExplore} />
			) : getCurrentMode?.value === SHOWCASE_MODE ? (
				<ShowCaseView />
			) : (
				<main
					className={`${
						showDrawer ? 'gx-pb-5 gx-mb-5 gx-mt-4' : 'gx-py-5 gx-my-5'
					} gx-container gx-d-flex`}
				>
					<section className="gx-flex-1" style={{ flexBasis: '100%' }}>
						{isMobile ? renderHeder() : null}
						<div className="gx-mb-5">
							<StateResults renderHeder={renderHeder} />
						</div>
						<Configure hitsPerPage={view === VIEW.QUICK ? 1 : 24} />
						{renderFooter()}
					</section>
					{openAdminPanel ? (
						<AdminPanel
							openAdminPanel={openAdminPanel}
							setOpenAdminPanel={arg => setOpenAdminPanel(arg)}
							title="Admin Control Panel"
							initiateSessionList={()=> {setOpenAdminPanel(false);setSessionList(true); }}
						/>
					) : null}
					{renderModalContent()}
				</main>
			)}
			<RefinementList className="filterBar gx-mb-4 " attribute="tracks" />
			<RefinementList className="filterBar gx-mb-4 " attribute="tags" />
			<RefinementList className="filterBar gx-mb-4 " attribute="sessions"/>
		</InstantSearch>
	);
};

export default KioskTab;
