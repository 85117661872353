import React from "react";

const GridImage = (props) => {

  const handleCancel = (index) => {
    props.handleToggle(index);
  };

    const {mediaList} = props;
    switch (mediaList.length) {
      case 1:
        return <div className="gx-gallery-item">
          <img className="gx-img-fluid" src={mediaList[0].image} alt="post" onClick={()=>handleCancel(0)}/>
        </div>;
      case 2:
        return <div className="gx-gallery-grid gx-gallery-2">
          <div className="gx-gallery-item">
            <img className="gx-img-fluid" src={mediaList[0].image} alt="post" onClick={()=>handleCancel(0)}/>
          </div>
          <div className="gx-gallery-item">
            <img className="gx-img-fluid" src={mediaList[1].image} alt="post" onClick={()=>handleCancel(1)}/>
          </div>
        </div>;
      case 3:
        return <div className="gx-gallery-grid gx-gallery-3">
          <div className="gx-gallery-item">
            <img className="gx-img-fluid" src={mediaList[0].image} alt="post" onClick={()=>handleCancel(0)}/>
          </div>
          <div className="gx-gallery-item">
            <img className="gx-img-fluid" src={mediaList[1].image} alt="post" onClick={()=>handleCancel(1)}/>
          </div>
          <div className="gx-gallery-item">
            <img className="gx-img-fluid" src={mediaList[2].image} alt="post" onClick={()=>handleCancel(2)}/>
          </div>
        </div>;
      case 4:
        return <div className="gx-gallery-grid gx-gallery-4">
          <div className="gx-gallery-item">
            <img src={mediaList[0].image} alt="post" onClick={()=>handleCancel(0)}/>
          </div>
          <div className="gx-gallery-item">
            <img src={mediaList[1].image} alt="post" onClick={()=>handleCancel(1)}/>
          </div>
          <div className="gx-gallery-item">
            <img src={mediaList[2].image} alt="post" onClick={()=>handleCancel(2)}/>
          </div>
          <div className="gx-gallery-item">
            <img src={mediaList[3].image} alt="post" onClick={()=>handleCancel(3)}/>
          </div>
        </div>;
      default:
        return <div className="gx-gallery-grid gx-gallery-4-more">
          <div className="gx-gallery-item">
            <img src={mediaList[0].image} alt="post" onClick={()=>handleCancel(0)}/>
          </div>
          <div className="gx-gallery-item">
            <img src={mediaList[1].image} alt="post" onClick={()=>handleCancel(1)}/>
          </div>
          <div className="gx-gallery-item thumb">
            <img src={mediaList[2].image} alt="post" onClick={()=>handleCancel(2)}/>
          </div>
          <div className="gx-gallery-item">
            <img src={mediaList[3].image} alt="post"/>
            <div className="gx-gallery-item-content">
              <h1 className="gx-text-white">+ {mediaList.length - 3}</h1>
            </div>
          </div>
        </div>
    }
};

export default GridImage;
