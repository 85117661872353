import moment from "moment";
import imageCompression from 'browser-image-compression';

let base_url  = "https://lag2r3gpz4.execute-api.us-east-2.amazonaws.com/dev/";

export function handleImageCompression(file) {
 return new Promise(function(resolve, reject) {
  var imageFile = file;

  var options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 600,
    useWebWorker: true
  }
  imageCompression(imageFile, options)
    .then(function (compressedFile) {
      //console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

      resolve(compressedFile) ;
    })
    .catch(function (error) {
      console.log(error.message);
    });

})
}


export function postComment(comment) {
  return new Promise(function(resolve, reject) {
    let url = base_url + "/comment";
    let paramData={
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(comment),
                        redirect: 'follow'
                      }
      fetch(url,paramData)
        .then(res => res.json())
        .then((response) => {
          resolve(response);
        })
  })
}
export function ToggleLikeRegister(post){
  return new Promise(function(resolve, reject) {
  let CommentID= post.CommentID;

  fetch(base_url + "/comment/"+CommentID, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(post),
            redirect: 'follow'
          })
            .then(response => response.text())
            .then(result => {
              })
            .catch(error => console.log('error', error));
    })
}


export function fetchTagInformation(tagTable,config){
  return new Promise(function(resolve, reject) {
    if(Object.keys(tagTable).length>0){
      let tagList =  Object.keys(tagTable).toString();
      let url = "https://masterapp.econference.io/masterapp_summer2012/controllers/communities/api_tagid.cfm?source="+config.source_hex+"&tag="+tagList;
      fetch(url)
          .then(res => res.json())
          .then((tagdata) => {
            resolve(tagdata);
          })
    }
    else{
      resolve(false)
    }

  })
}
export function compareValues(key, order = 'asc') {
      return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          // property doesn't exist on either object
          return 0;
        }

        const varA = (typeof a[key] === 'string')
          ? a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string')
          ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return (
          (order === 'desc') ? (comparison * -1) : comparison
        );
      };
}
export function deletePost(post, isAdmin){
  return new Promise(function(resolve, reject) {
   
    if(post.topic_identifier.includes('webinar') || isAdmin){
      post.message_object.obj?post.message_object.obj.discussion_board_deleted = true:post.message_object.obj = {discussion_board_deleted :true}
      
      fetch(base_url + "/comment/"+post._id, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            body: JSON.stringify(post)
          })
            .then(response => response.text())
            .then(result => {
              resolve(true);
            })
    }
    else{
      fetch(base_url + "/comment/"+post.CommentID, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json'
            },
          })
            .then(response => response.text())
            .then(result => {
              if(post.hasOwnProperty('commentList') && post.commentList.length>0){
                 for(let i=0; i<post.commentList.length; i++){
                  deletePost(post.commentList[i]);
                }
              }
              resolve(true);
            })
    }


  })
}
export function AddPostBundler (commentText, imageList, parentPost, config, params, user){
    return new Promise(function(resolve, reject) {
        const post = {
          ParentCommentID:parentPost==null?0:(parentPost.GrandParentCommentID && parentPost.GrandParentCommentID.length>1?parentPost.ParentCommentID:parentPost.CommentID),
          topic_identifier:params.topic_identifier,
          ConfID:config.conferenceid,
          source_hex:config.source_hex,
          author: {"id_type": "uuid","id": user.uuid, firstname:user.Firstname,lastname:user.Lastname,company:user.Company,image:user.Picture?user.Picture.startsWith("http")?user.Picture:config.appurl+ "/pictures_mobile/" + user.Picture:''},
          created_timestamp:  moment().unix(),
          mediaList: imageList?imageList.map(data => {
            return {image: data.file_url}
          }):null,
          isLike: false,
          commentCount: 0,
          commentList: [],
          likes:[],
          TotalComments:0,
          secure: 0,
          tags: params.NewPostTags,
        };
        if(parentPost && parentPost.ParentCommentID && parentPost.ParentCommentID.length>1){
          post.GrandParentCommentID= parentPost.GrandParentCommentID && parentPost.GrandParentCommentID.length>1?parentPost.GrandParentCommentID:parentPost.ParentCommentID
        }
        let message_object={};
        if(imageList && imageList.length>0){
          message_object = {
                message_type: "image",
                body: commentText,
                title: '',
                attachments: imageList
          }
        }else{
          message_object = {
                message_type: "text",
                body: commentText,
                title: ''
          }
        }
        // Abstract QNA

        if(params.qna) {
          // need to update message object with question object.
          message_object = {
            "message_type": "question",
            "body": commentText,
            "title": "",
            "obj": {
               "approved": Boolean(config.session_config?.auto_approve_abstract_qna),
               "ab_id": params.ab_id
            },
            "attachments": []
          }

          // also need to update post object.
          post.topic_identifier = `webinar_${config.source_hex}_session_${params.session_id}`;
        }
        post.message_object = message_object;
        postComment(post).then(response =>{
          post.CommentID=response.CommentID;
          post._id=response.CommentID;
          let RelatedTags=[];
          if(params && params.DisplayRelatedTags && params.pageType=='comment' && post.ParentCommentID==0){
            if(post.hasOwnProperty('tags') && post.tags.length>0){
              let abstractID = post.tags.filter(s => s.includes('abstract'))[0];
              let sessionID = post.tags.filter(s => s.includes('session'))[0];
              if(abstractID){
                RelatedTags.push(abstractID);
              }
              else if(sessionID){
                RelatedTags.push(sessionID);
              }
            }
          }
          post.RelatedTags=RelatedTags;
          
          resolve(post);
        })
     })
  }
export function updatePost(post){
  return new Promise(function(resolve, reject) {
      fetch(base_url + "/comment/"+post._id, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            body: JSON.stringify(post)
          })
            .then(response => response.text())
            .then(result => {
              resolve(true);
            })
  })
}

export function getTotalComments(topic){
  let TotalComments =topic.commentList?topic.commentList.length:0;
   if(topic.commentList){for(let i=0; i<topic.commentList.length; i++){
    TotalComments = TotalComments + topic.commentList[i].commentList.length;
   }}
   topic.TotalComments = TotalComments;
   return topic;
}


export function getChildComments(data, topic){
  let comments = [];
  let response =0;
  if(topic.topic_identifier?.includes('webinar') && topic.message_object){
    if(topic.message_object && topic.message_object.obj && topic.message_object.obj.response){
      let child = {
        CommentID : topic.message_object.obj.uuid,
        Level: 1,
        ParentCommentID: topic.CommentID,
        TotalComments: 0,
        author:{
          id_type: "uuid",
          id:topic.message_object.obj.uuid
        },
        message_object:{message_type: "text", body: topic.message_object.obj.response},
        likes:[],
        commentList:[],
        qna_response:true
      }
    
      response=1
      comments.push(child);
    }
  }
  for(let j=0; j<data.length; j++){
   if(topic.CommentID==data[j].ParentCommentID && !topic.message_object.obj?.discussion_board_deleted && !data[j].message_object.obj?.discussion_board_deleted){
      //adding children
        if(topic.Level==0 || !topic.Level){
          data[j].Level=1;
        }
        else if(topic.Level==1){
            data[j].Level=2;
            data[j].GrandParentCommentID=topic.ParentCommentID;
        }
        comments.push(data[j]);
        comments.sort(compareValues('created_timestamp'));
      }
    }
    return comments
}

