import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useSettings } from '../hooks/useSettings';
import { useTags } from '../hooks/useTags';

import { Card, Image, Button, Tag } from 'antd';
import { FullscreenOutlined, VideoCameraFilled, CaretUpFilled, ArrowLeftOutlined } from '@ant-design/icons';

import { FullScreenViewer } from 'iv-viewer';
import 'iv-viewer/dist/iv-viewer.css';

import Author from './Author';
import PDFViewer from 'components/common/Media/PDFViewer';
import { COLOR } from 'constants.js';

import VideoPlayer from 'components/common/Media/VideoPlayer';

import { Tabs } from 'antd';
import SocialFeed from "components/wall/index";

import JitsiHolder from 'components/jitsi';
import PosterButton from './PosterButton';

const { TabPane } = Tabs;
const fullscreenViewer = new FullScreenViewer();

const Poster = ({ poster }) => {
	const [settingsLoading, settings] = useSettings();
	const user = useSelector(state => state.auth.user);
	const history = useHistory();

	const [tagsLoading, tagsMap] = useTags();
	const loading = settingsLoading || tagsLoading;
	const [showVideo, setShowVideo] = useState(false);
	if (loading) return null;

	const track = settings.trackMap.get(poster.trackId);

	const handleGoback = () => {
		history.action === 'PUSH' ? history.goBack() : history.push('../main')
	}


	return (
		<main>
			<div className="gx-mb-4">
				<h1 className="gx-font-weight-semi-bold fg-primary-dark gx-mb-2">{poster.title}</h1>
				{track && (
					<Tag color={track?.color === '#' ? settings.primary : track.color}>
						{track?.name ?? poster.trackId}
					</Tag>
				)}
			</div>
			<PosterButton
				title="Back to Posters"
				click={handleGoback}
				Icon={ArrowLeftOutlined}
			/>

			{settings.showPdf ? (
				<Card className="card gx-mb-4">
					<PDFViewer url={poster.pdf} />
				</Card>
			) : (
				<Card className="card gx-mb-4 gx-p-4">
					{/* <Button
						onClick={() => fullscreenViewer.show(poster.image || require('assets/images/placeholder.png'))}
						icon={<FullscreenOutlined />}
						className="btn-white"
					>
						View Poster Full Screen
					</Button> */}
					<b>Pinch and zoom to interact with the poster.</b>
					{poster.original.video_links_array?.length > 0 && (
						<>
							<Button
								onClick={() => { setShowVideo(!showVideo) }}
								icon={!showVideo ? <VideoCameraFilled /> : <CaretUpFilled />}
								className={!showVideo ? "btn-white" : "gx-btn-orange"}

							>
								{!showVideo ? 'Show Video' : 'Hide Video'}
							</Button>
							<div style={{ 'display': showVideo ? 'block' : 'none' }}>
								<VideoPlayer url={poster.original.video_links_array[0].href}
									showVideo={showVideo}
								/>
							</div>
						</>
					)}
					<Image
						// onClick={() => fullscreenViewer.show(poster.image || require('assets/images/placeholder.png'))}
						src={poster.image || require('assets/images/placeholder.png')}
						alt="poster image"
						className="gx-pointer"
						preview={false}
					/>
				</Card>
			)}

			<section className="gx-d-flex gx-align-items-start">
				<Card className="gx-mr-4 gx-mb-0" style={{ flexBasis: '65%' }}>
					<div>
						{poster.tagIds.map(tagId => (
							<Tag
								key={tagId}
								className="gx-mb-3"
								color={settings.primary ?? COLOR.PRIMARY}
							>
								{tagsMap.get(tagId)?.name ?? tagId}
							</Tag>
						))}
					</div>

					{(settings.original.poster_app?.enable_video || settings.original.poster_app?.enable_discussion) && (
						<Tabs defaultActiveKey="1" >
							<TabPane tab="Description" key="1">
								<p
									className="gx-mb-4"
									dangerouslySetInnerHTML={{ __html: poster.description }}
								></p>
							</TabPane>
							{
								(settings.original.poster_app?.enable_discussion) && (
									<>
										<TabPane tab="Discussion" key="3">
											{user && (<SocialFeed config={settings.original}
												params={{ key: `conference_${settings.source}_${poster.sessionId}_${poster.id}`, session_id: 1, ab_id: 1, qna: false, author: [], NewPostTags: ['test'], topic_identifier: `conference_${settings.source}_${poster.sessionId}_${poster.id}` }}
												wall={false} key={'test'} />)}

											{!user && (<p
												className="gx-mb-4"
												dangerouslySetInnerHTML={{ __html: settings.original.poster_app?.login_message }}
											></p>)}

										</TabPane>

									</>

								)
							}
							{
								(settings.original.poster_app?.enable_video) && (
									<TabPane tab="Video Chat" key="2">
										{user && (<JitsiHolder roomName={poster.sessionId + poster.id}
											displayName={`${user.Firstname} ${user.Lastname}`}
											containerStyle={{ width: '100%', height: '100%' }}
											loadingComponent={() => {
												return (
													<>
														<div className="gx-comment-box">
															<h2>Connecting ...
															</h2>
															<img src={"/loader.svg"} alt="loader" height={50} />
														</div>
													</>)
											}}
											// containerStyle={{ width: '100%', height: '600px' }}
											interfaceConfig={{ CONNECTION_INDICATOR_DISABLED: true }}
											key={`holder_${poster.sessionId + poster.id}`}
											config={{ prejoinPageEnabled: false }}
										/>)}

										{!user && (<p
											className="gx-mb-4"
											dangerouslySetInnerHTML={{ __html: settings.original.poster_app?.login_message }}
										></p>)}

									</TabPane>
								)
							}

						</Tabs>
					)}

					{/* Removing Duplicate Description */}
					{!(settings.original.poster_app?.enable_video || settings.original.poster_app?.enable_discussion) && (
						<><h3 className="fg-primary gx-font-weight-semi-bold heading-underlined">
							Description
						</h3>
							<p
								className="gx-mb-4"
								dangerouslySetInnerHTML={{ __html: poster.description }}
							></p>
						</>
					)
					}

				</Card>
				<Card className="gx-mb-0" style={{ flexBasis: '35%' }}>
					<h3 className="fg-primary gx-font-weight-semi-bold gx-mb-4 heading-underlined">
						Authors
					</h3>

					{poster.authors.map(author => (
						<div key={author.id} style={{ marginBottom: '2rem' }}>
							<Author author={author} nameClass="fg-primary-dark" />
						</div>
					))}
				</Card>
			</section>
		</main>
	);
};

export default Poster;
