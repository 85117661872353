import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import settings from './settings';
import posters from './posters';
import tags from './tags';
import Social from "./Social";
import auth from "./auth";
import ModesReducer from './designModes';


const createRootReducer = history =>
	combineReducers({
		router: connectRouter(history),
		settings,
		posters,
		tags,
		social: Social,
		auth,
		modes: ModesReducer
	});

export default createRootReducer;
