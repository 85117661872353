import React from 'react';
import ReactPlayer from 'react-player';

import './index.css';

const VideoPlayer = ({ url, expanded, showVideo }) => (
	<main className="player-wrapper" style={{ height: expanded ? '65vh' : '50vh' }}>
		<ReactPlayer
			url={url}
			width="100%"
			height="100%"
			controls
			config={{ file: { attributes: { controlsList: 'nodownload' } } }}
			onContextMenu={e => e.preventDefault()}
			playing={showVideo}
		/>
	</main>
);

export default VideoPlayer;
