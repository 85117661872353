import React from 'react';
import { Button } from 'antd';
import { ReactComponent as Filter } from 'assets/icons/filter.svg';
import { connectCurrentRefinements } from 'react-instantsearch-dom';

const ClearRefinements = ({ items, refine }) => (
	<Button onClick={() => refine(items)} disabled={!items.length}>
		<div className="gx-d-flex gx-align-items-center">
			<Filter className="gx-mr-2" style={{ width: '.9rem' }} />
			<p className="gx-mb-0">Clear Filters</p>
		</div>
	</Button>
);
export default connectCurrentRefinements(ClearRefinements);
