export const REQUEST_POSTERS = 'REQUEST_POSTERS';
export const RECEIVE_POSTERS = 'RECEIVE_POSTERS';

export const requestPosters = payload => ({
	type: REQUEST_POSTERS,
	payload,
});

export const receivePosters = payload => ({
	type: RECEIVE_POSTERS,
	payload,
});
