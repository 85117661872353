import { RECEIVE_SETTINGS } from '../actions/settings';

const initialState = {
	settings: null,
	fetched: false,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case RECEIVE_SETTINGS: {
			const tracks = payload.data.program_track_array?.map(track => ({
					id: track.program_track_id,
					name: track.program_track_name,
					nameURL: track.program_track_name_urlenc,
					color: `#${track.program_track_color}`,
				})) ?? [];
			const trackMap = new Map(tracks.map(track => [track.id, track]));
			const settings = {
				title: payload.data.title,
				appUrl: payload.data.appurl,
				appDir: payload.data.appdir,
				urlId: payload.data.confurlid,
				conferenceId: payload.data.conferenceid,
				source: payload.data.source_hex,
				primary: payload.data?.styling?.['primary-color'],
				primaryDark: payload.data?.styling?.dark,
				primaryLight: payload.data?.styling?.primary_light_color,
				secondary: payload.data?.styling?.['secondary-color'],
				bannerWide: payload.data.core_app_images?.home1200x200,
				banner: payload.data.core_app_images?.home750x300,
				showPdf: payload.data.poster_config?.render_pdf,
				rooms: payload.data.session_room_array?.reduce((acc, room) => { return {...acc, [room.session_room_id]: room.session_room_name}}, {}),
				tracks,
				trackMap,
				original: { ...payload.data },
			};
			return { ...state, settings, fetched: true };
		}
		default:
			return state;
	}
};
