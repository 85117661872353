import React, { useContext, useMemo } from 'react';

import { RefinementList } from 'react-instantsearch-dom';
import ClearRefinements from './ClearRefinements';
import { useSettings } from '../../hooks/useSettings';
import { useTags } from '../../hooks/useTags';
import { Row, Col, Button } from 'antd';

import { Skeleton } from 'antd';
import { usePosters } from '../../hooks/usePosters';
import _ from 'lodash';
import { SearchContext } from '../../MainPage/SearchContext';

String.prototype.trimEllip = function (length) {
	return this.length > length ? this.substring(0, length) + '...' : this;
};

const FilterSidebar = ({ loading, showTags, showTracks, setIsModelOpen,filterPostersFromfacet, toggleDrawer, drawerVisibility, showSession = false }) => {
	const [settingsLoading, settings] = useSettings();
	const [tagsLoading, tagsMap] = useTags();
	const [ postersLoading, posters, sessionPosters ] = usePosters()
	const { searchState } = useContext(SearchContext);

	const sessionIdSet = useMemo(
		() => postersLoading || Array.from(new Set(sessionPosters?.map(p => p?.session?.id))),
		[postersLoading, sessionPosters],
	);

	const trackIdSet = useMemo(
		() => postersLoading || Array.from(new Set(posters?.filter(p => p?.trackId)?.map(p => p?.trackId))),
		[postersLoading, sessionPosters],
	);

	const tagIdSet = useMemo(
		() => postersLoading || Array.from(new Set(posters.flatMap(p => p.tagIds))),
		[postersLoading, sessionPosters],
	);

	const _loading = settingsLoading || tagsLoading || loading;

	const showMoreFunc = (type) => {
		if(type === 'sessions') {
			if(searchState?.refinementList?.tracks?.length > 0) {
				const filteredPosters = _.uniqBy(posters?.filter(e=> searchState?.refinementList?.tracks.includes(e?.trackId?.toString())), (e)=> e?.id) ?? [];
				return _.uniq(filteredPosters?.map(e => e?.sessionId))?.length
			} else {
				return sessionIdSet?.length
			}
		} else if(type === 'tags') {
			return tagIdSet?.length
		}
	}

	if (_loading)
		return (
			<main>
				<Skeleton.Button
					active
					size="large"
					style={{ marginBottom: '2.5rem' }}
				></Skeleton.Button>

				<h3 className="gx-mb-2">Tracks</h3>
				<hr className="gx-mt-0 gx-mb-2" />
				<Skeleton.Button
					active
					size="large"
					style={{ marginBottom: '2.5rem' }}
				></Skeleton.Button>

				<h3 className="gx-mb-2">Sessions</h3>
				<hr className="gx-mt-0 gx-mb-2" />
				<Skeleton
					active
					title={false}
					paragraph={{ rows: 6 }}
					className="gx-mb-4"
				></Skeleton>

				<h3 className="gx-mb-2">Tags</h3>
				<hr className="gx-mt-0 gx-mb-2" />
				<Skeleton active title={false} paragraph={{ rows: 6 }}></Skeleton>
			</main>
		);

	return (
		<main>
			<Row className="ant-space-align-baseline">
				<Col span={12} align="left">
					{showTracks && <h3 className="gx-mb-2">Tracks</h3>}
				</Col>
				<Col span={12} align="right">
					<ClearRefinements />
				</Col>
			</Row>

			{showTracks && (
				<>
					<hr className="gx-mt-0 gx-mb-2" />
					<RefinementList
						className="gx-algolia-refinementList gx-mb-4 "
						attribute="tracks"
						showMore={trackIdSet?.length > 10}
						showMoreLimit={trackIdSet?.length ?? 10}
						transformItems={tracks =>
							tracks
								.sort((a, b) => parseInt(a.label) - parseInt(b.label))
								.map(track => ({
									...track,
									label:
										settings.trackMap
											.get(parseInt(track.label))
											?.name ?? track.label,
								}))
						}
					/>
				</>
			)}
			{
				showSession && !!(settings?.original?.poster_app?.enable_session_filter) && (
					<>
						<h3 className="gx-mb-2">Sessions</h3>
						<hr className="gx-mt-0 gx-mb-2" />
						<RefinementList
							className="gx-algolia-refinementList gx-mb-4 "
							attribute="sessions"
							showMore={showMoreFunc('sessions') > 10}
							showMoreLimit={sessionIdSet?.length ?? 10}
							transformItems={sessions =>
								sessions.sort((a, b) => parseInt(a.label) - parseInt(b.label)).map((track, idx) => {
									return {
										...track,
										key: idx,
										label: sessionPosters?.find(e => e?.session?.id == track?.label)?.session?.title
									}
								})
							}
						/>
					</>
				)
			}
			{showTags && (
				<>
					<h3 className="gx-mb-2">
						{settings?.original?.app_language_overwrite?.tag_filter
							? settings.original.app_language_overwrite.tag_filter
							: 'Tags'}
					</h3>
					<hr className="gx-mt-0 gx-mb-2" />
					<RefinementList
						className="gx-algolia-refinementList drawer"
						attribute="tags"
						showMore={showMoreFunc('tags') > 10}
						showMoreLimit={tagIdSet?.length ?? 10}
						transformItems={tags =>
							{
							return tags
								.sort((a, b) => parseInt(a.label) - parseInt(b.label))
								.map(tag => ({
									...tag,
									label: tagsMap.get(parseInt(tag.label))?.name ?? tag.label,
								}))
							}
						}

						limit={200}
					/>
				</>
			)}
			<Row justify="center" className="gx-mt-2">
				<Col>
					<Button
						className="bg-primary-dark filterBtn"
						onClick={() => {
							if(drawerVisibility)
								toggleDrawer(); 
							setIsModelOpen(false)
						}}
					>
						Close
					</Button>
				</Col>
			</Row>
		</main>
	);
};

export default FilterSidebar;
