export const STANDARD_MODE = 'standard_mode';
export const KIOSK_MODE = 'kiosk_mode';
export const TAKEOVER_MODE = 'takeover_mode';
export const SHOWCASE_MODE = 'showcase_mode';

export const DesignModes = [
	{
		value: STANDARD_MODE,
		label: 'Standard Mode',
	},
	{
		value: KIOSK_MODE,
		label: 'Kiosk Mode',
	},
	{
		value: TAKEOVER_MODE,
		label: 'Takeover Mode',
	},
	{
		value: SHOWCASE_MODE,
		label: 'Showcase Mode',
	},
];

export const getDefaultMode = () => DesignModes.find(e => e.value === STANDARD_MODE);
