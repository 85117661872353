import React, {useEffect, useState} from "react";
import {Avatar, Card, Divider, Button} from "antd";

interface Props {
  user:{Biography: string,
    City: string,
    Company: string,
    ContactTitle: string,
    Country: string,
    CustID: number,
    DateAdded: string,
    Email: string,
    Firstname: string,
    Lastname: string,
    Phone: string,
    Picture: string,
    ReviewID: number,
    SourceHexID: string,
    State: string,
    Zip: string,
    uuid: string,
    Degrees:string,
    Prefix:string,
    CustomField_3:string,
    CustomField_4:string},
  handleAddPost:(message:string)=>void,
  post:{Level?:number, author:{firstname:string, lastname:string}, message_object:{body:string}}, 
  isEdit?:boolean, 
    config:any,
  handleUpdate:any;
}

const CommentEditor = (props:Props) => {

  const [message, setMessage] = useState(props.post.Level==2?'@' + props.post.author.firstname:"");
  const [user, setUser] = useState(props.user);
  const [imageUrl, setImageUrl] = useState('');
  const isEnabled = message === "";
  useEffect(() => {
    let image = '';
    if(props.user.Picture) {
      if(props.user.Picture.startsWith("http")) {
        image = props.user.Picture;
      } else {
        image = props.config.appurl + "/pictures_mobile/" + props.user.Picture;
      }
      } else {
        image = "https://ui-avatars.com/api/?name=" + encodeURIComponent(props.user.Firstname + " " + props.user.Lastname) + "&size=128&background=" + props.config.primary_light_color.substr(1) + "&color=000";
      }
      setImageUrl(image)
    setUser(props.user);
    if(props.isEdit){
      setMessage(props.post.message_object.body);
    }
  }, [props.user]);

  const updateCommentValue = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(evt.target.value);
  };
  const onPost = () => {
      props.handleAddPost(message);
      setMessage('');
  };

  const onUpdate = () => {
    let post = props.post;
    post.message_object.body= message;
    props.handleUpdate(post);
    setMessage('');
  }

  

  return (
      <div className="gx-media gx-mb-2" style={{marginBottom:10}}>
          {!props.isEdit &&
            <Avatar className="gx-mr-3 gx-size-36" src={imageUrl}/>
          }
            <div className="gx-media-body">
                <textarea
                  id="required" className="gx-border-0 ant-input commentEditor"
                  onChange={(event) => updateCommentValue(event)}
                  value={message}
                  placeholder="Type Comments"
                />
            </div>
            <Divider/>
            <div className="ant-row-flex">
              {!props.isEdit &&
                <Button type="primary" size='small' className="gx-ml-auto gx-mb-0"
                      disabled={(isEnabled) ? true : false}
                      onClick={onPost}>Post
              </Button>
              }
              {props.isEdit &&
                <Button type="primary" size='small' className="gx-ml-auto gx-mb-0"
                      disabled={(isEnabled) ? true : false}
                      onClick={onUpdate}>Update
              </Button>
              }
              
            </div>
    </div>
  )
};

export default CommentEditor;
