import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { requestPosters } from 'store/actions/posters';

import { useSettings } from './useSettings';

export const usePosters = () => {
	const dispatch = useDispatch();
	const [settingsLoading, settings] = useSettings();
	const posters = useSelector(state => state.posters.posters);
	const sessionPosters = useSelector(state => state.posters.sessionPosters);
	const postersFetched = useSelector(state => state.posters.postersFetched);

	const loading = settingsLoading || !postersFetched;

	useEffect(() => {
		if (settingsLoading || postersFetched) return;
		dispatch(requestPosters({ source: settings.source, conferenceId: settings.conferenceId }));
	}, [settingsLoading]);

	return [loading, posters, sessionPosters];
};
