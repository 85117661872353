import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useSettings } from './useSettings';
import { requestTags } from 'store/actions/tags';

export const useTags = () => {
	const dispatch = useDispatch();
	const [settingsLoading, settings] = useSettings();

	const tagsMap = useSelector(state => state.tags.tagsMap);
	const fetched = useSelector(state => state.tags.fetched);

	const loading = settingsLoading || !fetched;

	useEffect(() => {
		if (settingsLoading || fetched) return;
		dispatch(requestTags({ source: settings.source }));
	}, [settingsLoading]);

	return [loading, tagsMap];
};
