export const REQUEST_TAGS = 'REQUEST_TAGS';
export const RECEIVE_TAGS = 'RECEIVE_TAGS';

export const requestTags = payload => ({
	type: REQUEST_TAGS,
	payload,
});

export const receiveTags = payload => ({
	type: RECEIVE_TAGS,
	payload,
});
