import React, {useEffect, useState} from "react";
import {Col, Row, Drawer,Button} from "antd";
// @ts-ignore
import _isEmpty from "lodash/isEmpty";
// @ts-ignore
import _get from "lodash/get";
import PostList from "./PostList/index";
import CustomScrollbars from "../../util/CustomScrollbars";
import { compareValues} from "./wall";
import Filters from "./Filters/index";

import CircularProgress from "../../components/CircularProgress/index";
// @ts-ignore
import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import Widget from "../Widget";
import { requestSocialData } from '../../store/actions';

import moment from 'moment';

interface Props{
  user:{
    Biography: string,
    City: string,
    Company: string,
    ContactTitle: string,
    Country: string,
    CustID: number,
    DateAdded: string,
    Email: string,
    Firstname: string,
    Lastname: string,
    Phone: string,
    Picture: string,
    ReviewID: number,
    SourceHexID: string,
    State: string,
    Zip: string,
    uuid: string,
    Degrees:string,
    Prefix:string,
    CustomField_3:string,
    CustomField_4:string
  },
  config:any,
  width:any,
  params:{
    topic_identifier:string,
    pageType:string, 
    NewPostTags:string[], 
    appliedFilters:string[], 
    DisplayRelatedTags:string[], 
    ads:Boolean,
    session_end_time?:number,
    key:string,
    qna? :boolean,
    ab_id?: number
  },
  wall:boolean,
  noScroll?:boolean,
  lobby?:boolean,
  callback?: any,
  requestSocialData:any,
  postList:any,
  contacts:any,
  session:any,
  fetchSessions:any,
  loading:boolean
}
interface params {
  topic_identifier:string,
  pageType:string, 
  NewPostTags:string[], 
  appliedFilters:string[], 
  DisplayRelatedTags:string[], 
  ads:Boolean
}
const SocialFeed = (props:Props) => {
  const [dataList, setdataList] = useState([] as any);
  const [allData, setAllData] = useState([] as any); 
  const [tagTable, settagTable] = useState([] as any);
  const [tagInfoArray, setTagInfoArray] = useState([] as any);
  const [postTypesFilter, setPostTypesFilter] = useState([] as any); 
  const [visible, setVisible] = useState(false);
  const [sortBy, setsortBy] = useState("Most Recent");
  const [loader, setLoader] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [searchValue, SetSearchValue] = useState("");
  const [appliedFilter, setAppliedFilter] = useState({sessionNames:[] as any, postType:["conference", "session"] as any , sortBy, socialSearch: '' as string});
  let postTypes =  [{label:"General", type:"conference"},{label:"Education", type:"session"}, {label:"My Posts", type:"mypost"}];
  if(props.config?.include_qna_in_discussion){
    postTypes.splice(2, 0, {label:"Live Q&A", type:"qnapost"});
  }
  useEffect(() => {

      if(props.config && !Object.keys(props.postList).includes(props.params.key) && !props.loading){

        props.requestSocialData(props.config, props.params, props.user, props.contacts, false, 0, [], false);
      }
      else if(props.config && Object.keys(props.postList).includes(props.params.key) && !props.loading){
        //call to fetch new post (isRefresh=true)
        // if(props.params.topic_identifier.includes('conference')){
        //   props.requestSocialData(props.config, props.params, props.user, props.contacts, false, props.postList[props.params.key].lastSync, props.postList[props.params.key].data, props.postList[props.params.key].tagTable);
        // }
      }
    },[props.config, props.contacts, props.params]);

    useEffect(() => {},[dataList])
    useEffect(() => {
      if(props.params.NewPostTags.length==1 && allData.length){
        appliedFilters(appliedFilter) 
      }
    },[allData])
    useEffect(() => {
      if(searchValue){
        socialSearch(searchValue);
      }
    },[appliedFilter])
    useEffect(() => {
      if(props.postList && props.postList[props.params.key] && props.postList[props.params.key].post && props.postList[props.params.key].tagInfoArray){
        let posts:any = Object.values(props.postList[props.params.key].post);
        let tagTable:any = props.postList[props.params.key].tagArray;
        let tagInfoTable:any = props.postList[props.params.key].tagInfoArray;
        setTagInfoArray(tagInfoTable);
          if(props.params.NewPostTags.length>1){
            //session or abstract post
            let filteredPosts=[];
            for(let i=0; i<posts.length; i++){
              if(props.params.NewPostTags.some(v=>{
                if(posts[i].topic_identifier.includes(v) && !v.includes('conference')){
                  if((posts[i].topic_identifier.includes('webinar') || posts[i].message_object.message_type=='question') && (!posts[i].message_object.obj.approved || posts[i].message_object.obj.discarded  || (props.params.session_end_time && props.params.session_end_time> moment().valueOf()))|| posts[i].message_object.obj?.discussion_board_deleted){
                    return false
                  }
                  else{
                    return true
                  }
                }
              })){
                filteredPosts.push(posts[i])
              }
            }
            if(props.params.qna) {
              filteredPosts = filteredPosts.filter((post) => post && post.message_object.message_type == 'question' && post.message_object.obj.ab_id == props.params.ab_id);
            } else {
              if(!!!props.config.include_qna_in_discussion) {
                filteredPosts = filteredPosts.filter((post) => post && post.message_object.message_type != 'question');
              }
            }
            setdataList(filteredPosts);
            setAllData(filteredPosts);
            settagTable(tagTable)
            getPostTypeNumbers(filteredPosts, postTypes);
            setLoader(false);
            if(props.callback) {
              props.callback(filteredPosts.length);
            }
  
          }
          else{
            let newPostList;
            if(props.params.topic_identifier.includes('conference')){
               newPostList = (posts.filter((post:any)=> {
                if((post.topic_identifier.includes('webinar') || post.message_object.message_type=='question') && (!post.message_object?.obj?.approved || post.message_object?.obj?.discarded  || (props.params.session_end_time && props.params.session_end_time> moment().valueOf()))|| post.message_object?.obj?.discussion_board_deleted){
                  return false
                }
                else{
                  return true
                }}))
            }
            else{
              newPostList = posts;
            }
            setdataList(newPostList);
            setAllData(newPostList);
            settagTable(tagTable)
            getPostTypeNumbers(newPostList, postTypes);
            setLoader(false);
            if(props.callback) {
              props.callback(newPostList.length);
            }
          }
          
        // add interval for refresh posts after 30 sec.  120000
        setRefresh(true);
        const interval = setInterval(() => {
         props.requestSocialData(props.config, props.params, props.user, props.contacts, true, props.postList[props.params.key].lastSync, props.postList[props.params.key].data, props.postList[props.params.key].tagTable);
        }, 120000);
        return () => clearInterval(interval);
          
      }
      
    },[props.postList])
  const getPostTypeNumbers = (data: string | any[], Posttypes: any | ((prevState: string) => string)) =>{
   
    for(let i=0; i<Posttypes.length; i++){
      let posts=[];
      for(let j=0; j<data.length; j++){
        if(Posttypes[i].type=="conference" && data[j].topic_identifier.includes(Posttypes[i].type)){
          posts.push(data[j]);
        }
        else if(Posttypes[i].type=="session" && (!data[j].topic_identifier.includes('webinar') && data[j].message_object.message_type!='question') && !data[j].topic_identifier.includes("conference")){
          posts.push(data[j]);
        }
        else if(Posttypes[i].type=="qnapost" && (data[j].topic_identifier.includes('webinar') || data[j].message_object.message_type=='question') && !data[j].topic_identifier.includes("conference")){
          posts.push(data[j]);
        }
        else if(Posttypes[i].type=="mypost" && data[j].author.id==props.user.uuid){
          posts.push(data[j]);
        }
      }
      Posttypes[i].count= posts.length;
    }
    setPostTypesFilter(Posttypes);
  } 
  const filterObj = {
    sessionNames:tagTable,
    postTypes:postTypesFilter
  }
const appliedFilters = (filters: any) =>{
  let filterData=[];
  let postNameFilter: any[] =[];
  let newPostList=[];
  if(filters){
    if(filters.sessionNames.length>0){
      for(let i=0; i<allData.length; i++){
        if(allData[i].tags.length>0){
          if(filters.sessionNames.some((v:any)=>allData[i].tags.includes(v))){
            filterData.push(allData[i]);
          }
        }
      }
    }
    else{
      filterData=allData;
    }
    if(filters.postType.length>0){
      for(let j=0; j<filters.postType.length; j++){
        for(let i=0; i<filterData.length; i++){
        if(filters.postType[j]=="conference" && filterData[i].topic_identifier.includes("conference") && postNameFilter.indexOf(filterData[i]) === -1){
          postNameFilter.push(filterData[i]);
        }
        else if(filters.postType[j]=="session" && !filterData[i].topic_identifier.includes("conference") && (!filterData[i].topic_identifier.includes('webinar') && filterData[i].message_object.message_type!='question') && postNameFilter.indexOf(filterData[i]) === -1){
          postNameFilter.push(filterData[i]);
        }
        else if(filters.postType[j]=="qnapost" && !filterData[i].topic_identifier.includes("conference") && (filterData[i].topic_identifier.includes('webinar') || filterData[i].message_object.message_type=='question') && postNameFilter.indexOf(filterData[i]) === -1){
          postNameFilter.push(filterData[i]);
        }
        else if(filters.postType[j]=="mypost" && filterData[i].author.id==props.user.uuid && postNameFilter.indexOf(filterData[i]) === -1){
          postNameFilter.push(filterData[i]);
        }
      }
      }
    }
    else{
      postNameFilter=filterData;
    }

    if(filters.sortBy){
      setsortBy(filters.sortBy);
      if(filters.sortBy=="Most Comments"){
        newPostList =  postNameFilter.sort(compareValues('TotalComments','desc'));
      }
      else if(filters.sortBy=="Most Liked"){
        newPostList =  postNameFilter.sort(function (one: { likes: string | any[]; }, other: { likes: string | any[]; }){return other.likes.length - one.likes.length});
      }
      else{
        newPostList =  postNameFilter.sort(compareValues('created_timestamp','desc'));
      }
    }
    setdataList(newPostList);
  }
  else{
    newPostList = postNameFilter
  }
  setAppliedFilter(filters)
}

const socialSearch = (value:any) =>{
  SetSearchValue(value);
  let searchList = [];
  if(value){
    searchList = dataList.filter((v:any) => v.message_object.body.toLowerCase().includes(value.toLowerCase()));
    setdataList(searchList)
  }
  else{
    appliedFilters(appliedFilter)
  }
}

const showDrawer = () => {
  setVisible(true);
};

const onClose = () => {
  setVisible(false);
};

  return (
    <>
      {loader ? <CircularProgress className="gx-wall-scroll"/> :
       <div className="height-100">
        <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="height-100">
                <div className={props.noScroll?'':"gx-wall-scroll1 scrollbox scrollbox_delayed height-100"} id={props.noScroll?'socialdiv':"scrollablediv"}>
                    <div key={"wall-"+sortBy} className="gx-wall-postlist scrollbox-content">
                      <PostList postList={dataList} user={props.user} wall={true} sortBy={sortBy} params={props.params} config={props.config} allPostData={props.postList}/>
                    </div>
                </div>
            </Col>
        </Row>
      </div>
    }
    </>
  )


};

function mapStateToProps(state: any) {
  return {
      user:state.auth.user,
      config:state.auth.config,
      postList:state.social.postList,
      contacts: {},
      session: state.session,
      width:state.settings.width,
      loading:state.social.loading,
  };
}
function matchDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators(
    Object.assign({
      requestSocialData:requestSocialData,
    }),
    dispatch
  );
}
export default connect(
  mapStateToProps,
  matchDispatchToProps
)(SocialFeed);