import {
    SIGNIN_USER_SUCCESS,
  } from "constants/ActionTypes";

export const REQUEST_SETTINGS = 'REQUEST_SETTINGS';
export const RECEIVE_SETTINGS = 'RECEIVE_SETTINGS';


export const requestSettings = payload => ({
	type: REQUEST_SETTINGS,
	payload,
});

export const receiveSettings = payload => ({
	type: RECEIVE_SETTINGS,
	payload,
});



export const signinUser = payload => ({
	type: SIGNIN_USER_SUCCESS,
	payload,
});
