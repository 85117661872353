import { RECEIVE_POSTERS } from '../actions/posters';

const initialState = {
	posters: null,
	sessionPosters: new Map(),
	postersFetched: false,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case RECEIVE_POSTERS: {
			return {
				...state,
				posters: payload.posters,
				sessionPosters: payload.sessionPosters,
				postersFetched: true,
			};
		}
		default:
			return state;
	}
};
