import React, { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { usePosters } from '../hooks/usePosters';

import Header from '../common/Header';
import Poster from 'components/common/Poster';
import Loading from 'components/common/Loading';

import './index.css';

const PosterPage = () => {
	const { posterId } = useParams();
	const [postersLoading, posters] = usePosters();
	const loading = postersLoading;

	useEffect(() => window.scrollTo(0, 0), []);

	const poster = useMemo(() => posters?.find(p => p.id === parseInt(posterId)), [posters]);

	if (loading) return <Loading />;

	return (
		<main>
			<Header showBack />
			<section className="gx-py-5 gx-my-5 gx-container">
				<Poster poster={poster} />
			</section>
		</main>
	);
};

export default PosterPage;
