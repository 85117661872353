import React, {useEffect, useState} from "react";
import {Avatar, Card, Divider, Button, Dropdown, Menu, Popover, Tag} from "antd";
import CommentBox from "./CommentBox";
import MediaList from "./MediaList";
import DisplayDate from "../DisplayDate/index";
import CommentEditor from "./CommentEditor";
import {deletePost, updatePost, ToggleLikeRegister, getChildComments, getTotalComments} from "../wall";
import moment from "moment";
// @ts-ignore
import { Link } from "react-router-dom";

// @ts-ignore
import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import Report from "../Report";
import { setSocialData } from '../../../store/actions/Social';

interface Props {
  user:{
    Biography: string,
    City: string,
    Company: string,
    ContactTitle: string,
    Country: string,
    CustID: number,
    DateAdded: string,
    Email: string,
    Firstname: string,
    Lastname: string,
    Phone: string,
    Picture: string,
    ReviewID: number,
    SourceHexID: string,
    State: string,
    Zip: string,
    uuid: string,
    Degrees:string,
    Prefix:string,
    CustomField_3:string,
    CustomField_4:string
    limited_access_attendee?:boolean,
    is_admin?:boolean
  },
  setSocialData:any,
  session:any
  key:string,
  index:string,
  config:any,
  wall:any,
  contacts:any,
  postList:any,
  postData:{
    CommentID?: string;
    id: number,
    text: string,
    author: {firstname:string,lastname:string,company:string,image:string, id?:string},
    created_timestamp: string,
    mediaList: [],
    RelatedTags:any,
    isLike: boolean,
    commentList: any,
    message_object:{
      message_type: string,
      body: string,
      title: string,
      attachments:[],
      obj?:any
    },
    likes:any
    TotalComments:number,
    Level?: number,
    tags:any,
    ParentCommentID?:any,
  },
  params:{
    topic_identifier:string,
    pageType:string,
    NewPostTags:string[],
    appliedFilters:string[],
    DisplayRelatedTags:string[],
    ads:Boolean,
    qna?: boolean,
    key:any
  },
  addPost:(message:any, invokedBy:any,post:any, config:any, params:{})=> Promise<any>;
}
interface postItem {
  CommentID?: string;
  id: number,
  text: string,
  author: {firstname:string,lastname:string,company:string,image:string, id?:string},
  created_timestamp: string,
  mediaList: any,
  RelatedTags:[],
  isLike: boolean,
  commentList: any,
  message_object:{
    message_type: string,
    body: string,
    title: string,
    attachments?:[],
    obj?:any
  },
  likes:[ {ReviewID: string,
    timestamp: number} | null]
  TotalComments:number,
  Level?: number,
  topic_identifier?:string
}
const PostItem = (props:Props) => {

  const [isComment, setIsComment] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isLike, setIsLike] = useState(false);
  const [visible, setVisible] = useState(false);
  const [comments, setComments] = useState([]);
  const [RelatedTags, setRelatedTags] = useState([] as any);
  const [author, setAuthor] = useState({} as any);
  const [totalComments, setTotalComments] = useState(0);
  const [mediaList, setMediaList] = useState([] as any);
  const [speaker, setSpeaker] = useState('' as any);
  const [post, setPost] = useState<postItem>({
    id: 0,
    text: '',
    author: {firstname:'',lastname:'',company:'',image:''},
    created_timestamp: '',
    mediaList: [],
    RelatedTags:[],
    isLike: false,
    commentList: [],
    message_object:{
      message_type: "",
      body: "",
      title: ""
    },
    likes:[null],
    TotalComments:0,
    topic_identifier:''
  });

  useEffect(() => {
    props.postData.Level=0;
    let liked = props.postData.likes.some((el: { ReviewID: string; })=> el.ReviewID == props.user.uuid);
    liked?setIsLike(true):setIsLike(false);
    if(props.postData.message_object.message_type=='question' && props.postData.message_object.obj?.ab_id && props.session.program?.sessions){
      let session:any = Object.values(props.session.program.sessions).filter((v:any) => v.ab_id && v.ab_id==props.postData.message_object.obj?.ab_id);
      if(session.length){
        let speaker = session[0].author_array.filter((v:any) => v.presenter)[0];
        let speakerName = `@${speaker.Firstname} ${speaker.Lastname}`;
        setSpeaker(speakerName)
      }
    }
    if(props.postData.message_object.message_type=='image' && props.postData.message_object.attachments){
     //add media/images
      let mediaListArray = props.postData.message_object.attachments.map( (data: any) => {
            return {image: data.file_url}
          });
      setMediaList(mediaListArray)
    }
    //add author info
    let contactsData = props.contacts;
    if(props.postData.author.id){
      let cont:any = Object.values(contactsData).filter((v:any) => v?.uuid==props.postData.author.id)[0];
      if(cont){
        let info = cont.contact?.length>0 ? cont.contact[0] : cont;
        author.id = info.uuid;
        author.firstname = info.Firstname;
        author.lastname = info.Lastname;
        author.image = info.Picture;
        author.company = info.Company;
        if(info.Picture) {
          if(info.Picture.startsWith("http")) {
            author.image = info.Picture;
          } else {
            author.image = props.config.appurl + "/pictures/" + info.Picture;
          }
          } else {
              author.image = "https://ui-avatars.com/api/?name=" + encodeURIComponent(info.Firstname + " " + info.Lastname) + "&size=128&background=" + props.config.primary_light_color.substr(1) + "&color=000";
          }
      }
      else{
        cont = props.postList[props.params.key].contactInfo[props.postData.author.id];
        if(cont){
          author.id = cont.uuid;
          author.firstname = cont.Firstname;
          author.lastname = cont.Lastname;
          author.image = cont.Picture;
          author.company = cont.Company;
          if(cont.Picture) {
            if(cont.Picture.startsWith("http")) {
              author.image = cont.Picture;
            } else {
              author.image = props.config.appurl + "/pictures/" + cont.Picture;
            }
            } else {
                author.image = "https://ui-avatars.com/api/?name=" + encodeURIComponent(cont.Firstname + " " + cont.Lastname) + "&size=128&background=" + props.config.primary_light_color.substr(1) + "&color=000";
            }
        }
      }
      setAuthor(author);
    }

    //add session links
    if(props.postData.tags && props.postData.tags.length>0){
      let sessionID = props.postData.tags.filter((s: string | string[]) => s.includes('session'))[0];
      let abstractID = props.postData.tags.filter((s: string | string[]) => s.includes('abstract'))[0];
      sessionID = sessionID?sessionID.split('_')[1]:'';
      abstractID = abstractID?abstractID.split('_')[1]:'';
      let RelatedTags = [];
      if(props.postData.RelatedTags && props.postData.RelatedTags.length>0){
        for(let i=0; i<props.postData.RelatedTags.length; i++){
          let rTag:any = {}
          if(props.postData.RelatedTags[i].tag && props.postData.RelatedTags[i].tag.includes('session')){
            let url = "/main/sessions/" + sessionID;
            rTag.url = url;
          }
          else if(props.postData.RelatedTags[i].tag && props.postData.RelatedTags[i].tag.includes('abstract')) {
            let url = "/main/sessions/" + sessionID + "/" + abstractID;
            rTag.url = url;
          }
          if(props.postList[props.params.key].tagInfoArray && !props.postData.RelatedTags[i].label){
            rTag.label = props.postList[props.params.key].tagInfoArray[props.postData.RelatedTags[i].tag];
          }
          else if(props.postList[props.params.key].tagInfoArray && props.postData.RelatedTags[i].label){
            rTag.label = props.postData.RelatedTags[i].label;
          }
          RelatedTags.push(rTag)
        }
      }
      setRelatedTags(RelatedTags);
    }
    //add child comments
    let commentData = props.postList[props.params.key].comments?Object.values(props.postList[props.params.key].comments):[];
   let commentsList:any = getChildComments(commentData, props.postData);
    setComments(commentsList);

    //add TotalComments comments
    let totalCount = commentsList.length;
    if(commentsList.length>0){
      for(let i=0; i<commentsList.length; i++){
        let childComments = getChildComments(commentData, commentsList[i]);
        totalCount = childComments.length>0?totalCount + childComments.length : totalCount;
      }
    }
    setTotalComments(totalCount);

    setPost(props.postData);
  }, [props.postData, props.postList]);

  useEffect(() => {},[comments, totalComments])

  const handleAddPost = (message: any) => {
      props.addPost(message, null,post, props.config, props.params).then((res:any)=>{})
      setIsComment(false);
  };

  const handleLikeToggle = () => {
    if(!props?.user?.limited_access_attendee){
      if(!isLike){
          post.likes.push({
            "ReviewID": props.user.uuid,
            "timestamp": moment().unix()
          })

          ToggleLikeRegister(post);
        }
        else{
          post.likes.splice( post.likes.findIndex((a:any )=> a.ReviewID === props.user.uuid) , 1);
          ToggleLikeRegister(post);
        }
      setIsLike(!isLike);
    }
  };

  const handleReplyToggle = () => {
    setIsComment(!isComment);
  };

  const handleCommentToggle = () =>{
    setShowComments(!showComments);
  };

  const handleEditPost = () =>{
    setEditMode(true);
  };

  const handleDeletePost = ()=>{
    deletePost(post, props.user.is_admin).then(res=>{
      let obj = {post:post, key:props.params.key, newPost:false, deletePost:true}
      props.setSocialData(obj);
      setComments(comments);
    })
  };

  const handleUpdate = (post: any) => {
    updatePost(post).then(res => {
      let obj = {post:post, key:props.params.key, newPost:true, deletePost:false}
      props.setSocialData(obj);
      setEditMode(false);
     })
  };
  const handleReport = () => {
    setVisible(true);
  };

  const {created_timestamp} = post;

  const menu = (
    <Menu>
      {(author.id==props.user.uuid || props.user.is_admin) && <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" onClick={handleDeletePost}>
          Delete
        </a>
      </Menu.Item>}
      {author.id==props.user.uuid && <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" onClick={handleEditPost}>
          Edit
        </a>
      </Menu.Item>}
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" onClick={handleReport}>
          Report
        </a>
      </Menu.Item>
    </Menu>
  );

  const likeCount = post.likes.length?post.likes.length:0;

  const text = post.message_object.body;

  let find_attendee:any = null;

    if(!find_attendee) {
        find_attendee = {
          uuid : author.id,
          Firstname: author.firstname,
          Lastname: author.lastname,
          Company: author.company
        };
        if(author.image) {
          if(author.image.startsWith("http")) {
            find_attendee.Picture = author.image;
          } else {
            find_attendee.Picture = props.config.appurl + "/pictures_mobile/" + author.image;
          }
          } else {
            find_attendee.Picture = "https://ui-avatars.com/api/?name=" + encodeURIComponent(author.Firstname + " " + author.Lastname) + "&size=128&background=" + props.config.primary_light_color.substr(1) + "&color=000";
          }

    }

  let content2 = (<></>);
  if((!props.config.include_qna_in_discussion && !props.params.qna) && post.message_object.message_type.includes('question')) {
    return false;
  }

  if(post.CommentID){
    return (
    <Card className="social-card">
      <Report
          visible={visible}
          setVisible={setVisible}
          config={props.config}
          user={props.user}
          post={post}
        />
      <div className="gx-wall-content">
          <div className="gx-media gx-wall-user-info gx-flex-nowrap gx-align-items-center">
            <div className="gx-media-body">
            <div style={{whiteSpace: 'nowrap'}}>
              <Popover placement="left" content={content2} trigger="hover" key={author.id}>

                <Avatar style={{display: 'inline-block', marginRight:5, verticalAlign:'middle'}} className={"gx-mr-2 gx-mb-2 gx-size-50"} src={author.image}/>
                <div style={{display: 'inline-block'}}>
                  <h5 className="gx-wall-user-title  gx-mt-2">{author.firstname + ' ' + author.lastname}</h5>
                  <DisplayDate date={created_timestamp}/>
                </div>
            </Popover>
            <div style={{float:'right',clear:'both'}}>
              <Dropdown overlay={menu}>
                <a className="feedAuthorMenu" onClick={e => e.preventDefault()}>
                  <i className="icon icon-ellipse-h gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle"/>
                </a>
              </Dropdown>

            </div>
            </div>
            {post.message_object && post.message_object.message_type.includes('question') && <Tag className="gx-rounded-xs" color={"#F55505"}>{"Question"}</Tag>}
            {speaker && <Tag className="gx-rounded-xs" color={"#2D8FAF"}>{speaker}</Tag>}
            </div>
          </div>

        {RelatedTags && RelatedTags.length>0  && !props.params.qna && RelatedTags.map((tag:any, i: string) => {
         return (
            <Link key={'tag'+i} to={{ pathname: tag.url}}>
              <div className="RelatedTag"  dangerouslySetInnerHTML={{ __html:tag.label }}>
              </div>
            </Link>
          )
        })}
        {editMode?
          <div style={{marginBottom:10,marginLeft:5}}>
             <CommentEditor config={props.config} user={props.user} handleAddPost={handleAddPost} post={post} isEdit handleUpdate={handleUpdate}/>
            </div>:
            <div className="TopicBody" dangerouslySetInnerHTML={{ __html: text }}></div>

        }

        <div className="gx-wall-medialist">
          {mediaList && mediaList.length > 0 ? <MediaList mediaList={mediaList}/> : null}
        </div>

        <div className="gx-flex-row gx-mb-2 gx-mb-xl-3">
          <p className="gx-fs-sm gx-pointer gx-mr-3 gx-text-grey" onClick={handleLikeToggle}>

              {isLike === true ?
              <i className="icon icon-like gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle"
                 style={{color: '#F40102'}}/> :
              <i className="icon icon-like-o gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle"/>}


            <span
              className="gx-d-inline-flex gx-vertical-align-middle">{likeCount == 1 ? likeCount + ' Like' : likeCount +' Likes'}</span>

          </p>
          {!props.params.qna && (
          <p className="gx-fs-sm gx-pointer gx-mr-3 gx-text-grey" onClick={handleCommentToggle}>
            <i className="icon icon-chat-bubble gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle"/>
            <span
              className="gx-d-inline-flex gx-vertical-align-middle">{totalComments && totalComments ==1 ? totalComments + ' Comment' : totalComments + ' Comments'}</span>
          </p>)}
          {!props?.user?.limited_access_attendee && !props.params.qna && (<p className="gx-fs-sm gx-pointer gx-mr-3 gx-text-grey" onClick={handleReplyToggle}>
            <i className="icon icon-reply gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle"/>
            <span
              className="gx-d-inline-flex gx-vertical-align-middle">{'Reply'}</span>
          </p>)}

        </div>

        {isComment === true ?<div className="gx-wall-comment-box editorBox">
          <CommentEditor config={props.config} user={props.user} handleAddPost={handleAddPost} post={post} handleUpdate={handleUpdate}/>
        </div> : null}
        {showComments &&<div className="gx-wall-comment-box">
          {comments && comments.length > 0 && comments.map((commentData: any, index: string | number | null | undefined) => <CommentBox params={props.params} config={props.config} key={index} index={index} commentData={commentData} user={props.user} addPost={props.addPost} />)}
        </div>}
      </div>
    </Card>
  )
  }
  else{
    return(<div></div>)
  }

};

const mapStateToProps = (state: any) => {
  return ({
      session: state.session,
      postList:state.social.postList,
      contacts: {},
  });
}
function matchDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators(
    Object.assign({
      setSocialData:setSocialData
    }),
    dispatch
  );
}
export default connect(
  mapStateToProps, matchDispatchToProps
)(PostItem);