import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import { REQUEST_SETTINGS, receiveSettings } from '../actions/settings';

const api =
	'https://masterapp.econference.io/masterapp_summer2012/controllers/mobileapp/settings/process_v2.cfm';

const getSettings = async ({ appDir, urlId }) =>
	await axios.get(`${api}?appdir=${appDir}&urlid=${urlId}`);

function* getSettingsSaga({ payload }) {
	const results = yield call(getSettings, payload);
	yield put(receiveSettings(results));
}

function* getSettingsWatcher() {
	yield takeLatest(REQUEST_SETTINGS, getSettingsSaga);
}

export default function* () {
	yield all([fork(getSettingsWatcher)]);
}
