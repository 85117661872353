import React, {useEffect, useState} from "react";
import {Avatar, Button, Divider, Dropdown, Menu, Popover} from "antd";
import DisplayDate from "../DisplayDate/index";
import {deletePost, updatePost, ToggleLikeRegister, getChildComments} from "../wall";
import CommentEditor from "./CommentEditor";
import moment from "moment";
// @ts-ignore
import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "redux";

import Report from "../Report";
import { setSocialData } from '../../../store/actions/Social';

interface Props {
  user:{
    Biography: string,
    City: string,
    Company: string,
    ContactTitle: string,
    Country: string,
    CustID: number,
    DateAdded: string,
    Email: string,
    Firstname: string,
    Lastname: string,
    Phone: string,
    Picture: string,
    ReviewID: number,
    SourceHexID: string,
    State: string,
    Zip: string,
    uuid: string,
    Degrees:string,
    Prefix:string,
    is_admin?:boolean,
    CustomField_3:string,
    CustomField_4:string},
  key:number,
  index:number,
  config:any,
  session?:any,
  contacts?:any,
  allPostData?:any,
  setSocialData:any,
  params:{
    topic_identifier:string,
    pageType:string, 
    NewPostTags:string[], 
    appliedFilters:string[], 
    DisplayRelatedTags:string[], 
    ads:Boolean,
    key:any
  },
  commentData:{
    CommentID?: string;
    id: number,
    text: string,
    author: {firstname:string,lastname:string,company:string,image:string, id:string},
    created_timestamp: string,
    mediaList: [],
    RelatedTags:[],
    isLike: boolean,
    commentList: any,
    message_object:{
      message_type: string,
      body: string,
      title: string,
      attachments:[]
    },
    likes:any
    TotalComments:number,
    Level?: number,
    qna_response?:boolean
  },
  addPost:(message:any, invokedBy:any,post:any, config:any, params:any)=> Promise<any>
}
interface postItem {
  CommentID?: string;
  id: number,
  text: string,
  author: {firstname:string,lastname:string,company:string,image:string, id?:string},
  created_timestamp: string,
  mediaList: any,
  RelatedTags:[],
  isLike: boolean,
  commentList: any,
  message_object:{
    message_type: string,
    body: string,
    title: string,
    attachments?:[]
  },
  likes:[ {ReviewID: string,
    timestamp: number} | null]
  TotalComments:number,
  Level?: number,
  qna_response?:boolean,
  GrandParentCommentID?:any,
  ParentCommentID?:any
}

const CommentBox = (props:Props) => {

  const [isComment, setIsComment] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isLike, setIsLike] = useState(false);
  const [visible, setVisible] = useState(false);
  const [comments, setComments] = useState([]);
  const [author, setAuthor] = useState({} as any);
  const [post, setPost] = useState<postItem>({
    id: 0,
    text: '',
    author: {firstname:'',lastname:'',company:'',image:''},
    created_timestamp: '',
    mediaList: [],
    RelatedTags:[],
    isLike: false,
    commentList: [],
    message_object:{
      message_type: "",
      body: "",
      title: ""
    },
    likes:[null],
    TotalComments:0
  });

  useEffect(() => {
        //add author info
        let contactInfo;
        let contactsData = props.contacts;
        if(props.commentData.author.id){
          let cont:any = Object.values(contactsData).filter((v:any) => v.uuid==props.commentData.author.id)[0];
            if(cont){
              let info = cont.contact?.length>0 ? cont.contact[0] : cont;
              author.id = info.uuid;
              author.firstname = info.Firstname;
              author.lastname = info.Lastname;
              author.image = info.Picture;
              author.company = info.Company;
              if(info.Picture) {
                if(info.Picture.startsWith("http")) {
                  author.image = info.Picture;
                } else {
                  author.image = props.config.appurl + "/pictures/" + info.Picture;
                }
                } else {
                    author.image = "https://ui-avatars.com/api/?name=" + encodeURIComponent(info.Firstname + " " + info.Lastname) + "&size=128&background=" + props.config.primary_light_color.substr(1) + "&color=000";
                }
            }
          else{
            cont = props.allPostData[props.params.key].contactInfo[props.commentData.author.id];
            if(cont){
              author.id = cont.uuid;
              author.firstname = cont.Firstname;
              author.lastname = cont.Lastname;
              author.image = cont.Picture;
              author.company = cont.Company;
              if(cont.Picture) {
                if(cont.Picture.startsWith("http")) {
                  author.image = cont.Picture;
                } else {
                  author.image = props.config.appurl + "/pictures/" + cont.Picture;
                }
                } else {
                    author.image = "https://ui-avatars.com/api/?name=" + encodeURIComponent(cont.Firstname + " " + cont.Lastname) + "&size=128&background=" + props.config.primary_light_color.substr(1) + "&color=000";
                }
            }
          }
          setAuthor(author);
        }

       //add child comments
      let commentData = props.allPostData[props.params.key].comments?Object.values(props.allPostData[props.params.key].comments):[];
      let commentsList:any = getChildComments(commentData, props.commentData);
      setComments(commentsList);

      setPost(props.commentData);
      let liked = props.commentData.likes.some((el: { ReviewID: string; }) => el.ReviewID == props.config.user_uuid);
      liked?setIsLike(true):setIsLike(false);
  }, [props.commentData, props.allPostData]);

  useEffect(() => {},[comments]);

  const handleLikeToggle = () => {
    if(!isLike){
          post.likes.push({
            "ReviewID": props.user.uuid,
            "timestamp": moment().unix()
          });
          ToggleLikeRegister(post);
        }
        else{
          post.likes.splice( post.likes.findIndex((a: any) => a.ReviewID === props.config.user_uuid) , 1);
          ToggleLikeRegister(post);
        }
      setIsLike(!isLike);
  };


  const handleReplyToggle = () => {
    setIsComment(!isComment);
  };

  const handleDeletePost = ()=>{
    deletePost(post, props.user.is_admin).then(res=>{
      let obj = {post:post, key:props.params.key, newPost:false, deletePost:true, comment:true};
      props.setSocialData(obj)

    });
  }


  const handleAddPost = (message: any) => {
    props.addPost(message, null,post, props.config, props.params).then((res:any) =>{})
    handleReplyToggle();
  };


  const handleCommentToggle = () =>{
    setShowComments(!showComments);
  }

  const handleEditPost = () =>{
    setEditMode(true);
  }

  const handleUpdate = (post: React.SetStateAction<postItem>) => {
    updatePost(post).then(res => {
       //setPost(post);
       let obj = {post:post, key:props.params.key, newPost:false, deletePost:false}
       props.setSocialData(obj);
       setEditMode(false);

     })
  }

  const handleReport = () => {
    setVisible(true);
  };

  const {Level} = post;
  const menu = (
    <Menu>
      {(author.id==props.user.uuid || props.user.is_admin) && <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" onClick={handleDeletePost}>
          Delete
        </a>
      </Menu.Item>}
      {author.id==props.user.uuid && <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" onClick={handleEditPost}>
          Edit
        </a>
      </Menu.Item>}
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" onClick={handleReport}>
          Report
        </a>
      </Menu.Item>
    </Menu>
  );

  const comment = post.message_object.body;
  const likeCount = post.likes.length?post.likes.length:0;
  let find_attendee:any = null;

    if(!find_attendee) {
        find_attendee = {
          uuid : author.id,
          Firstname: author.firstname,
          Lastname: author.lastname,
          Company: author.company
        };
        if(author.image) {
          if(author.image.startsWith("http")) {
            find_attendee.Picture = author.image;
          } else {
            find_attendee.Picture = props.config.appurl + "/pictures_mobile/" + author.image;
          }
          } else {
            find_attendee.Picture = "https://ui-avatars.com/api/?name=" + encodeURIComponent(author.Firstname + " " + author.Lastname) + "&size=128&background=" + props.config.primary_light_color.substr(1) + "&color=000";
          }
    }

  let content2 = (<></>);
  if(post.CommentID){
    return (
      <div>
        <Report
          visible={visible}
          setVisible={setVisible}
          config={props.config}
          user={props.user}
          post={post}
        />
      
            <div style={{float:'right',clear:'both'}}>
            <Dropdown overlay={menu}>
              <a className="feedAuthorMenu" onClick={e => e.preventDefault()}>
                <i className="icon icon-ellipse-h gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle"/>
              </a>
            </Dropdown>
          </div>

      <div className="gx-media gx-flex-nowrap gx-wall-user-info gx-mb-3">
      <Popover placement="left"  content={content2} trigger="hover" key={author.image}>
        <Avatar className="gx-mr-2 gx-size-40" src={author.image}/>
        </Popover>
        <div className="gx-media-body">
        <Popover placement="left"  content={content2} trigger="hover" key={author.id}>
          <h5 className="gx-wall-user-title">{author.firstname + ' ' + author.lastname}</h5>
          </Popover>
          {!post.qna_response && <DisplayDate date={post.created_timestamp}/>}
          {post.qna_response && <div className="RelatedTag" style={{cursor:'none'}}>
                {" Live Q&A Response"}
              </div>}
          {editMode?
            <div style={{marginBottom:10,marginLeft:5}}>
             <CommentEditor config={props.config} user={props.user} handleAddPost={handleAddPost} post={post} isEdit handleUpdate={handleUpdate}/>
            </div>:
          <div className="TopicBody" dangerouslySetInnerHTML={{ __html: comment }}></div>
         }
          {!post.qna_response && <div className="gx-flex-row gx-mb-2 gx-mb-xl-3">
            <p className="gx-fs-sm gx-pointer gx-mr-3 gx-text-grey" onClick={handleLikeToggle}>
                {isLike === true ?
                  <i className="icon icon-like gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle"
                     style={{color: 'red'}}/> :
                  <i className="icon icon-like-o gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle"/>}
                <span
                  className="gx-d-inline-flex gx-vertical-align-middle">{likeCount == 1 ? likeCount + ' Like' : likeCount +' Likes'}</span>

              </p>
            {comments && Level!=2 &&
                <p className="gx-fs-sm gx-pointer gx-mr-3 gx-text-grey" onClick={handleCommentToggle}>
                  <i className="icon icon-chat-bubble gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle"/>
                  <span
                    className="gx-d-inline-flex gx-vertical-align-middle">{comments && comments.length ==1 ? comments.length + ' Comment' : comments.length + ' Comments'}</span>
                </p>
              }

            <p className="gx-fs-sm gx-pointer gx-mr-3 gx-text-grey" onClick={handleReplyToggle}>
              <i className="icon icon-reply gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle"/>
              <span
                className="gx-d-inline-flex gx-vertical-align-middle">{'Reply'}</span>
            </p>
          </div>}
          {isComment === true ? <div className="gx-media editorBox">
             <CommentEditor config={props.config} user={props.user} handleAddPost={handleAddPost} post={post} handleUpdate={handleUpdate}/>
          </div> : null}
          {showComments &&<div className="gx-wall-comment-subbox">
            {comments && comments.length > 0 && comments.map((commentData:any, index: number) => <CommentBox params={props.params} session={props.session} config={props.config} key={index} index={index} commentData={commentData} user={props.user} addPost={props.addPost} allPostData={props.allPostData} setSocialData={props.setSocialData} contacts={props.contacts}/>)}
          </div>}

          </div>
        </div>
      </div>
    )
  }
  else{
    return(<div></div>)
  }
};


const mapStateToProps = (state: any) => {
  return ({
      session: state.session,
      contacts: {},
      allPostData:state.social.postList,
  });
}
function matchDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators(
    Object.assign({
      setSocialData:setSocialData
    }),
    dispatch
  );
}
export default connect(
  mapStateToProps, matchDispatchToProps
)(CommentBox);