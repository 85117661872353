import React, { useMemo } from 'react';
import ReactWordcloud from 'react-wordcloud';
import { usePosters } from '../../hooks/usePosters';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { removeStopwords } from 'stopword';
import { updateSearchParams } from '../../../store/actions/designModes';
import { TAKEOVER_MODE } from '../../../constants/ModesConfig';
import { useSelector } from 'react-redux';
import { Button, Card, Col, Row } from 'antd';

function ExploreMode({ handleExplore }) {
	const [postersLoading, posters] = usePosters();
	const dispatch = useDispatch();
	const getCurrentMode = useSelector(state => state.modes?.activeMode);

	const handleTextColors = () => {
		const colors = [
			'#D8A499',
			'#F0B7A4',
			'#FFD3B5',
			'#FFE9C7',
			'#FFD9A4',
			'#FFD4A3',
			'#FFEB9C',
			'#FFE59C',
			'#F6F2AA',
			'#C3DDB8',
			'#A3D9B1',
			'#A9D0CA',
			'#A1D8E2',
			'#8DB9CA',
			'#92A6D0',
			'#8C90C8',
			'#C2A7CF',
			'#D7A9D6',
			'#D8A6C1',
			'#DDA2A4',
		];
		return colors[Math.floor(Math.random() * colors.length)];
	};

	const callbacks = {
		getWordColor: () => handleTextColors(),
		onWordClick: word => handleWordClick(word.text),
	};

	const posterSortedWords = useMemo(() => {
		let filteredPosters = posters;

		if (getCurrentMode.value == TAKEOVER_MODE) {
			filteredPosters = [
				...posters.filter(item => {
					if (
						getCurrentMode.tracks.includes(item.trackId) ||
						item.tagIds.some(item => getCurrentMode.tags.includes(item))
					) {
						return item;
					}
				}),
			];

			if (!filteredPosters.length) filteredPosters = posters;
		}

		const titles = filteredPosters.map(poster => poster.title);
		const text = titles.join(' ');
		const words = _.words(_.toLower(text), /[\w']+/g);
		const filteredWords = removeStopwords(words);
		const wordCount = _.countBy(filteredWords);
		const maxFrequency = _.max(_.values(wordCount));

		// Normalize frequencies in the range of 0.5 to 3.5
		const normalizedWords = _.mapValues(wordCount, frequency => {
			const normalized = ((frequency - 1) / (maxFrequency - 1)) * 3 + 0.5;
			return _.round(normalized, 2);
		});

		const sortedWords = _.chain(normalizedWords)
			.toPairs()
			.orderBy([1], ['desc'])
			.take(40)
			.fromPairs()
			.value();
		return Object.entries(sortedWords).map(item => ({
			text: item[0],
			value: item[1] * 40,
		}));
	}, [posters]);

	const options = {
		rotations: 0,
		fontFamily: 'IBM Plex Sans',
		fontSizes: [20, 140],
		transitionDuration: 1000,
		enableTooltip: false,
		padding: 20,
		scale: 'sqrt',
		spiral: 'archimedean',
	};

	const handleWordClick = word => {
		dispatch(updateSearchParams(word));
		handleExplore(false);
	};

	return (
		<div className="explore">
			<Button
				className="bg-primary-dark filterBtn exitBtn"
				onClick={() => handleExplore(false)}
				style={{left: '75%'}}
			>
				Exit
			</Button>
			<Row justify="center" style={{ Top: '5%' }}>
				<Col span={21}>
					<Card>
					Click a keyword to see related posters.
						<ReactWordcloud
							className="wordcloud"
							style={{ height: '60vh', background: '#2a3b4a' }}
							callbacks={callbacks}
							options={options}
							words={posterSortedWords}
						/>
					</Card>
				</Col>
			</Row>
		</div>
	);
}
export default ExploreMode;
