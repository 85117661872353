import React from 'react';
import { Result, Button } from 'antd';

const EmptyPage = () => {
	return (
		<main className="gx-d-flex" style={{ height: '100vh' }}>
			<Result
				className="gx-m-auto"
				status="404"
				title="404"
				subTitle="Sorry, the page you are visiting does not exist."
				// extra={<Button onClick={history.push()} type="primary">Go to main page</Button>}
			/>
		</main>
	);
};

export default EmptyPage;
