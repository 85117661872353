import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useSettings } from './useSettings';
import { signinUser } from 'store/actions/settings';

import {
    SIGNIN_USER_SUCCESS,
  } from "constants/ActionTypes";


function fetchContactInfo(ids, config) {
	return new Promise(function (resolve, reject) {
		let url =
			'https://lag2r3gpz4.execute-api.us-east-2.amazonaws.com/dev/contact/' +
			config.source;
		let uuids = { uuids: ids };
		let paramData = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(uuids),
		};
		fetch(url, paramData)
			.then(res => res.json())
			.then(contactsData => {
				resolve(contactsData);
			});
	});
}



export const useUser = () => {
    const location = useLocation();
	const [settingsLoading, settings] = useSettings();
    const [params, setParams] = useState(new URLSearchParams(location.search));
    const [userInfo, setUserInfo] = useState();
    const [fetched, setFetched] = useState(false);
    const [enabled, setEnabled] = useState(params.get('uuid')?true:false);
	const dispatch = useDispatch();
	useEffect(() => {
        if(enabled && !fetched) {
            fetchContactInfo([params.get('uuid')], settings).then((contactObj) => {
                setFetched(true);
                if(contactObj.length > 0) {
                    setUserInfo(contactObj[0]);
                    dispatch(signinUser({ config: settings.original, user:contactObj[0] }));
                }
            });
        }
	}, [settingsLoading]);

	return [enabled, fetched, userInfo];
};
