import React from 'react';
import { connectPagination } from 'react-instantsearch-dom';
import { Pagination as AntPagination } from 'antd';

const Pagination = ({ currentRefinement, nbPages, refine }) => (
	<AntPagination
		current={currentRefinement}
		onChange={page => {
			window.scrollTo(0, 0);
			refine(page);
		}}
		total={nbPages}
		pageSize={1}
		showSizeChanger={false}
	/>
);

export default connectPagination(Pagination);
