import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { REQUEST_POSTERS, receivePosters } from '../actions/posters';

const getPosters = async ({ source, conferenceId }) => {
	const request = await axios.get(
		`https://pe97nxhj35.execute-api.us-east-2.amazonaws.com/dev2/session/${source}/${conferenceId}`,
	);
	return await axios.get(request.data.json);
};

const getAuthors = async ({ source, uuids }) =>
	await axios.post(
		`https://pe97nxhj35.execute-api.us-east-2.amazonaws.com/dev2/contact/${source}?${uuids.size}`,
		{ uuids: [...uuids] },
	);

function* getPostersSaga({ payload }) {
	// fetch posters
	const { data: _posters } = yield call(getPosters, payload);

	// filter and map sessions
	const sessions = _posters
		.filter(poster => poster.type === 'session')
		.map(session => ({
			id: session.session_id,
			title: session.title,
			original: { ...session },
		}));

	// filter posters
	let posters = _posters.filter(poster => poster.poster_links_array.length);

	// fetch authors
	const uuids = new Set(
		posters.flatMap(poster => poster.author_array.map(author => author.contact_uuid)),
	);
	const { data: _authors } = yield call(getAuthors, { source: payload.source, uuids });

	// map authors
	const authors = _authors.map(author => ({
		uuid: author.uuid,
		prefix: author.Mr,
		firstName: author.Firstname,
		lastName: author.Lastname,
		suffix: author.Namesuffix,
		phone: author.Phone,
		avatar: author.Picture,
		email: author.Email,
		company: author.Company,
		title: author.ContactTitle,
		biography: author.Biography,
		lastLogin: author.LastLogin,
		address: {
			street: author.Address2,
			state: author.State,
			country: author.Country,
		},
		original: { ...author },
	}));

	// build authorsMap
	const authorsMap = new Map(authors.map(author => [author.uuid, author]));

	// map posters
	posters = posters.map(poster => ({
		id: poster.ab_id,
		title: poster.title,
		description: poster.description,
		authors: poster.author_array.map(author => ({
			id: author.contact_id,
			uuid: author.contact_uuid,
			...authorsMap.get(author.contact_uuid),
			original: { ...author },
		})),
		tagIds: poster.tags,
		trackId: poster.program_track_id,
		type: poster.type,
		thumbnail: poster.poster_links_array?.[0]?.thumbnail_url,
		image: poster.poster_links_array?.[0]?.image_url,
		pdf: poster.poster_links_array?.[0]?.href,
		sessionId: poster.session_id,
		original: { ...poster },
	}));
	posters.sort((a, b) => (a.title > b.title ? 1 : -1));

	// build sessionPosterMap
	const sessionPosterMap = new Map();
	for (const poster of posters) {
		if (!poster.sessionId) continue;
		if (!sessionPosterMap.has(poster.sessionId)) {
			sessionPosterMap.set(poster.sessionId, {
				session: {
					id: poster.sessionId,
					title: sessions.find(session => session.id === poster.sessionId)?.title,
					room_id: sessions.find(session => session.id === poster.sessionId)?.original.room_id
				},
				posters: [],
			});
		}
		sessionPosterMap.get(poster.sessionId).posters.push(poster);
	}
	const sessionPosters = [...sessionPosterMap.values()];
	sessionPosters.sort((a, b) => (a.session.title > b.session.title ? 1 : -1));

	yield put(receivePosters({ posters, sessionPosters }));
}

function* getPostersWatcher() {
	yield takeLatest(REQUEST_POSTERS, getPostersSaga);
}

export default function* () {
	yield all([fork(getPostersWatcher)]);
}
