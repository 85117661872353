import { getDefaultMode } from '../../constants/ModesConfig';
import { UPDATE_MODES, DEFAULT_SETTINGS, UPDATE_PARAMS } from '../actions/designModes';

const initialState = {
	activeMode: {
		name: getDefaultMode().label,
		value: getDefaultMode().value,
		tracks: [],
		tags: [],
		timeout: 0,
		RotateTimer:0
	},
	siteConfig: {},
	params : ''
};
const ModesReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case UPDATE_MODES: {
			return {
				...state,
				activeMode: payload,
			};
		}
		case DEFAULT_SETTINGS: {
			return {
				...state,
				siteConfig: payload,
			};
		}
		case UPDATE_PARAMS : {
			return{
				...state,
				params : payload
			}
		}
		default:
			return state;
	}
};

export default ModesReducer;
