import React, { useContext, useMemo, useState } from 'react';
import { FilterFilled } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import FilterSidebar from '../common/Algolia/FilterSidebar';
import useDocumentWidth from './useDocumentWidth';
import { usePosters } from './usePosters';
import { SearchContext } from '../MainPage/SearchContext';
import { useSelector } from 'react-redux';
import { SHOWCASE_MODE, TAKEOVER_MODE } from '../../constants/ModesConfig';

export default function useDrawer({setIsModelOpen, filterPostersFromfacet=[]}) {
  const [postersLoading, posters, sessionPosters] = usePosters();
  const { isMobile } = useDocumentWidth();
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const getCurrentMode = useSelector(state => state.modes?.activeMode);

  const trackIdSet = useMemo(
    () => postersLoading || new Set(posters.flatMap(p => p.trackId ?? [])),
    [postersLoading, posters],
  );

  const tagIdSet = useMemo(
    () => postersLoading || new Set(posters.flatMap(p => p.tagIds)),
    [postersLoading, posters],
  );

  const sessionIdSet = useMemo(
		() => postersLoading || new Set(sessionPosters?.map(p => p?.session?.id)),
		[postersLoading, sessionPosters],
	);
    console.log('sessionIdSet',sessionIdSet);
 
  const toggleDrawer = () => { setDrawerVisibility(f => !f) }
  const { searchState } = useContext(SearchContext);
  const filterCount = useMemo(() => {
    let count = 0
    Object.entries(searchState?.refinementList ?? {}).forEach(([key, value]) => {
      count += value.length

    })
    return count;
  }, [searchState.refinementList])
  const renderSiderBar = () => (
    <div className='gx-m-4'>
      <FilterSidebar
        loading={postersLoading}
        showTracks={trackIdSet.size > 0}
        showTags={tagIdSet.size > 0}
        showSession={sessionIdSet.size > 0}
        setIsModelOpen={setIsModelOpen}
        toggleDrawer={toggleDrawer}
        drawerVisibility={drawerVisibility}
        filterPostersFromfacet={filterPostersFromfacet}
      />
    </div>
  )
  const showSideBar = (postersLoading || sessionIdSet.size > 0 || trackIdSet.size > 0 || tagIdSet.size > 0)
  const renderDrawer = () => (
    <>
      {isMobile && (getCurrentMode?.value !== SHOWCASE_MODE &&
								getCurrentMode?.value !== TAKEOVER_MODE) &&  (
        <Button className='bg-primary-dark filterBtn' icon={<FilterFilled />} onClick={toggleDrawer}>
          Filter
          {
            filterCount ? <span className="filterCount"> {filterCount}</span> : null
          } 
        </Button>
      )}
      <Drawer
        placement="left"
        visible={drawerVisibility}
        onClose={toggleDrawer}
        size='large'
      >
        {renderSiderBar()}
      </Drawer>
    </>
  )
  return { showDrawer: isMobile, showSideBar, renderSiderBar, renderDrawer }
}
