import React, { useContext } from 'react';

import { connectStateResults } from 'react-instantsearch-dom';
import { Card, Skeleton, Image, Empty } from 'antd';

import PostersView from '../PostersView';
import { ViewContext } from '../ViewContext';
import { VIEW } from 'constants.js';

const StateResults = ({ searchResults, loading }) => {
	const { view } = useContext(ViewContext);

	const searchLoading = loading || !searchResults;

	if (searchLoading)
		return (
			<section className="poster-grid gx-mt-4">
				{[...Array(8)].map((_, index) => (
					<Card key={index} className="gx-mb-0 -shadow-1">
						<Image
							className="card-image"
							style={{ height: '7em' }}
							alt="placeholder"
							src={require('assets/images/placeholder.png')}
						/>
						<Skeleton active paragraph={{ rows: 5 }}></Skeleton>
					</Card>
				))}
			</section>
		);
	if (searchResults.nbHits === 0) return <Empty style={{ margin: '20em 0' }} />;

	return (
		<>
			{view !== VIEW.QUICK && (
				<p>
					showing {searchResults.hits.length} out of {searchResults.nbHits} results
				</p>
			)}
			<PostersView posters={searchResults.hits} />
		</>
	);
};

export default connectStateResults(StateResults);
