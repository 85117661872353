export const UPDATE_MODES = 'UPDATE_MODES';
export const DEFAULT_SETTINGS = 'DEFAULT_SETTINGS';
export const UPDATE_PARAMS = 'UPDATE_PARAMS'

export const updateDesignModes = payload => ({
	type: UPDATE_MODES,
	payload,
});

export const updateDefaultSettings = payload => ({
	type: DEFAULT_SETTINGS,
	payload,
});

export const updateSearchParams = payload => ({
	type : UPDATE_PARAMS,
	payload
})

