import React, { useContext } from 'react';

import { connectStateResults } from 'react-instantsearch-dom';
import { Card, Skeleton, Image, Empty } from 'antd';
import { ReactComponent as Presenter } from 'assets/icons/presenter.svg';

import PostersView from '../PostersView';
import Author from '../../common/Author';
import { ViewContext } from '../ViewContext';
import { VIEW } from 'constants.js';

const PresenterResults = ({ searchResults, loading }) => {
	const { view } = useContext(ViewContext);
	const searchLoading = loading || !searchResults;

	if (searchLoading)
		return (
			<section className="poster-grid">
				{[...Array(8)].map((_, index) => (
					<Card key={index} className="gx-mb-0 -shadow-1">
						<Image
							className="card-image"
							style={{ height: '7em' }}
							alt="placeholder"
							src={require('assets/images/placeholder.png')}
						/>
						<Skeleton active paragraph={{ rows: 5 }}></Skeleton>
					</Card>
				))}
			</section>
		);
	if (searchResults.nbHits === 0) return <Empty style={{ margin: '20em 0' }} />;

	return (
		<section className="">
			{view !== VIEW.QUICK && (
				<p>
					showing {searchResults.hits.length} out of {searchResults.nbHits} results
				</p>
			)}
			{searchResults.hits.map((presenterPoster, index) => (
				<Card
					title={
						<div className="gx-text-white gx-d-flex">
							<Author author={presenterPoster.presenter} nameClass="gx-text-white" />
							<Presenter
								className="gx-ml-auto gx-pl-3"
								style={{ flexShrink: 0, flexBasis: '2.75rem' }}
							/>
						</div>
					}
					className="author-card"
					key={index}
				>
					<PostersView posters={presenterPoster.posters} showAuthors={false} />
				</Card>
			))}
		</section>
	);
};

export default connectStateResults(PresenterResults);
